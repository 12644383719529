@import '../../../styles/base';

$item-margin: 36px;

.header-nav {
  align-items: center;
  display: flex;

  > .header-nav-link,
  .header-nav__item {
    margin-right: $item-margin;

    @include breakpoint(desktop) {
      margin-right: 25px;
    }
  }

  .btn {
    font-size: 14px;
    margin-right: 20px;
  }

  .button_link {
    margin-right: 20px;
  }

  &__separator {
    border-left: 2px solid color(gray-vl);
    margin: 0 20px 0 #{40px - $item-margin};
    height: 28px;
  }

  > :last-child {
    margin-right: 0;
  }

  &__account {
    cursor: pointer;

    &__handler {
      display: inline-flex;
      align-items: center;
    }

    &__full-name {
      align-items: center;
      display: inline-flex;
      margin-right: 10px;
    }

    &__avatar {
      margin-right: 10px;
    }

    ~ .dropdown-content--bottom-center {
      bottom: 6px;
    }
  }

  &__burger.btn {
    .icon {
      left: 0;
    }

    button {
      padding: 8px 10px;
    }
  }
}
