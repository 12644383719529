@import '../../../dm-public/ui-kit/variables';

.success-form-modal {
  width: 570px;

  .modal__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: $white;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;

    h3 {
      max-width: 400px;
      margin-top: 20px;
      line-height: 29px;
      text-align: center;
    }

    p {
      margin: 15px 0;
      font-size: 18px;
      text-align: center;

      @include breakpoint(tablet_p, zero) {
        font-size: 16px;
      }
    }
  }
}
