@import '../../../styles/base';

.header_nav_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 20px;
  cursor: pointer;

  .icon {
    position: relative;
    line-height: 0;
  }

  .amount {
    position: absolute;
    top: -3px;
    right: -7px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 14px;
    min-width: 16px;
    background: color(red);
    border-radius: 4px;
    color: color(white);
    font-size: 11px;
  }

  .text {
    color: color(texts2);
    font-size: 12px;
    line-height: 18px;

    @include breakpoint(tablet, zero) {
      display: none;
    }
  }

  &:hover {
    .text {
      color: color(texts);
    }
  }
}
