@import '../../../styles/base';

$url: url(../../../images/icons/icons_large.png);

@each $name, $icon in $icons-lg {
  @if $name != 'url' {
    .icon-lg {
      $this: &;

      &--#{$name} {
        @include icon-lg($name, $url);

        &#{$this}--resizable {
          @include breakpoint(desktop, zero) {
            @include icon-lg('#{$name}--desktop', $url);
          }
        }
      }
    }
  }
}
