@import '../../../styles/base';

$sizes: (
  xs-padding: 6px,
  md-padding: 10px,
);

$xs-height: 18px;
$sm-height: 24px;
$md-height: 32px;

$xs-padding: 6px;
$md-padding: 10px;

$icon-space: 21px;

@mixin tag-sizes($this, $padding, $icon-space) {
  &#{$this}--icon {
    @each $side in (left, right) {
      &--#{$side} {
        padding-#{$side}: #{$padding + $icon-space};

        .icon {
          #{$side}: $padding;
        }
      }
    }
  }
}

.tag {
  $this: &;
  align-items: center;
  border-radius: 4px;
  border-width: 1px;
  box-sizing: border-box;
  color: color(white);
  display: inline-flex;
  font-size: 14px;
  height: $md-height;
  padding: 0 $md-padding;
  position: relative;

  &-group {
    display: inline-flex;
  }

  // sizes
  &--xs {
    @include tag-sizes($this, $xs-padding, $icon-space);
    font-size: 10px;
    height: $xs-height;
    padding: 0 $xs-padding;
    text-transform: uppercase;
  }

  &--sm {
    @include tag-sizes($this, $md-padding, $icon-space);
    font-size: 12px;
    height: $sm-height;
  }

  &--md {
    @include tag-sizes($this, $md-padding, $icon-space);
  }

  // icon
  &--icon {
    .icon {
      @extend %vertical-center;
    }

    &--center {
      .icon {
        @extend %center-center;
      }
    }

    &--clickable {
      .icon {
        cursor: pointer;
      }
    }
  }

  // colors
  @each $color-name, $color in $colors {
    &--#{$color-name} {
      background-color: $color;
      border-color: $color;

      &--flat {
        color: $color;
      }
    }
  }

  // states
  &--flat {
    background-color: transparent;
  }
}
