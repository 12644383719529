@import '../../../../styles/base';

$border: 1px solid color(sbg);

.document {
  align-items: center;
  border-top: $border;
  display: flex;
  padding: 14px 30px;

  &:last-child {
    border-bottom: $border;
  }

  &__file {
    color: color(blue-g);
    flex: 1;
    margin-left: 19px;
    overflow: hidden;
    width: 0;

    .link {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .icon-lg {
    flex-shrink: 0;
  }

  .icon {
    cursor: pointer;
  }

  &--checkable {
    padding-left: 70px;
    position: relative;

    @include breakpoint(mobile) {
      padding-left: 58px;
    }

    .checkable {
      left: 30px;
      margin-right: 22px;
      position: absolute;

      @include breakpoint(mobile) {
        left: 20px;
      }
    }

    .btn {
      margin-left: 15px;

      @include breakpoint(desktop, infinity) {
        visibility: hidden;
      }

      @include breakpoint(tablet, zero) {
        visibility: visible;
      }
    }

    &:hover {
      .btn {
        visibility: visible;
      }
    }
  }
}
