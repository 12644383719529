@import '../../../styles/base';

.notes {
  display: flex;
  flex: 1;
  position: relative;

  &__controls {
    align-items: center;
    border-bottom: 1px solid color(gray-vl);
    color: color(gray-d);
    display: flex;
    justify-content: space-between;
    height: 60px;
    padding: 0 48px 0 70px;
    position: relative;

    @include breakpoint(mobile) {
      height: 50px;
      padding: 0 25px 0 60px;
    }

    &__delete {
      align-items: center;
      display: flex;
      flex: 1;
      justify-content: flex-end;
    }

    &__amount-selected {
      margin: 0 5px 0 10px;
    }

    .checkable {
      left: 30px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);

      @include breakpoint(mobile) {
        left: 20px;
      }
    }
  }

  &__main {
    flex: 1;

    .message__container {
      padding: 30px 48px 30px 70px;

      @include breakpoint(mobile) {
        padding: 20px 25px 20px 60px;
      }
    }
  }

  &__new-message {
    border-bottom: 1px solid color(gray-vl);
    display: flex;
    flex-wrap: wrap;
    padding: 30px 48px 30px 70px;
    position: relative;

    @include breakpoint(mobile) {
      padding: 20px 25px 20px 60px;
    }

    &__header {
      align-items: center;
      display: flex;
      flex: 1 0 100%;
      margin-bottom: 9px;
    }

    &__name {
      color: color(headings);
      font-size: 16px;
      margin-left: 10px;
    }

    &__role {
      color: color(blue-m);
      font-size: 12px;
      font-weight: 700;
      margin-left: 10px;
      position: relative;
      text-transform: lowercase;
    }

    &__main {
      box-sizing: border-box;
      display: flex;
      flex: 0 1 842px;
      padding-left: 42px;

      @include breakpoint(mobile) {
        flex-direction: column;
        padding-left: 0;
      }
    }

    &__controls {
      display: flex;
      flex-direction: column;
      margin-left: 20px;

      @include breakpoint(mobile) {
        flex-direction: row-reverse;
        justify-content: space-between;
        margin: 10px 0 0;
      }

      .btn {
        width: 100px;

        @include breakpoint(mobile) {
          width: 80px;
        }

        &:first-child {
          @include breakpoint(tablet) {
            margin-bottom: 10px;
          }
        }

        &:last-child {
          @include breakpoint(mobile) {
            margin-left: -15px;
          }
        }
      }
    }
  }
}
