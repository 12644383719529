@import './functions';
@import './variables';


@mixin breakpoint($point, $side: null) {
  $point-list: get-list-item($point, $breakpoint-list);
  $min-width: nth($point-list, 2);
  $max-width: nth($point-list, 3);
  @if $side == zero {
    $min-width: null;
  } @else if $side == infinity {
    $max-width: null;
  }
  @if $min-width and $max-width {
    @media only screen and (min-width: $min-width) and (max-width: $max-width) { @content; }
  } @else if $min-width {
    @media only screen and (min-width: $min-width) { @content; }
  } @else if $max-width {
    @media only screen and (max-width: $max-width) { @content; }
  }
}

@mixin icon-custom($icons, $name, $url) {
  $icon: map-get($icons, $name);

  @if length($icon) >= 2 {
    $height: nth($icon, 2);
    $width: nth($icon, 1);
    @if $height and $width {
      background: transparent $url no-repeat;
      content: '';
      display: inline-block;
    }
    @if $height {
      height: #{$height}px;
    }
    @if $width {
      width: #{$width}px;
    }
  }
  @if length($icon) >= 3 {
    background-position: nth($icon, 3);
  }
  @if length($icon) >= 4 {
    $static-position: nth($icon, 3);
    $hover-position: nth($icon, 4);

    &:hover {
      background-position: $hover-position;
    }

    // TODO: try to optimize has-icons mechanizm
    @at-root {

      .has-icons--active &,
      .has-icons:hover & {
        background-position: $hover-position;
      }

      // .has-icons:hover .has-icons & {
      //   background-position: $static-position;
      // }
      //
      // .has-icons .has-icons:hover & {
      //   background-position: $hover-position;
      // }
    }
  }
}

@mixin icon($name, $url) {
  @include icon-custom($icons-sm, $name, $url);
}

@mixin icon-lg($name, $url) {
  @include icon-custom($icons-lg, $name, $url);
}
