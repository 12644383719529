@import '../../../../../styles/base';

.guest-nav-modal {
  .modal__close {
    right: 13px;

    @include breakpoint(tablet) {
      right: 23px;
    }
  }

  .modal__container,
  .nav-modal__body {
    background: color(sbg);
  }

  .nav-modal__header {
    padding: 0 8px;

    @include breakpoint(tablet) {
      padding: 0 18px;
    }
  }

  .button_link {
    margin: 0 10px 20px;
  }

  &__list {
    .header-nav-link {
      margin-bottom: 20px;
      font-size: 15px;
      line-height: 18px;
    }

    .header-nav-link:hover,
    .header-nav-link--active {
      color: color(blue-m);
    }
  }
}
