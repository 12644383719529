@import '../../../../styles/base';

.logo {
  align-self: flex-start;
  display: inline-flex;

  .image {
    height: 100px;
    margin-right: 30px;
    width: 100px;
  }

  &__placeholder {
    align-items: center;
    align-self: stretch;
    border: 3px dashed color(gray-vl);
    color: color(gray-l);
    display: flex;
    flex: 1;
    font-size: 14px;
    font-weight: 700px;
    justify-content: center;
    text-align: center;
    text-transform: uppercase;
  }

  &__controls {
    position: relative;

    span {
      display: block;
    }
  }

  &__extensions {
    margin-top: 4px;

    @include breakpoint(tablet, zero) {
      font-size: 12px;
    }
  }

  &--error & {
    &__placeholder {
      border: 3px dashed color(red-error);
      color: color(red-error);
    }
  }

  .btn {
    @include breakpoint(tablet) {
      font-size: 14px;
    }

    @include breakpoint(mobile) {
      font-size: 12px;
      min-height: 34px;
    }
  }
}
