@import '../../../../styles/base';

.search_funds {
  display: flex;
  width: 100%;

  .container {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 370px;
    margin: 0 20px;
  }
}

.mobile {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 10;

  .container {
    max-width: 100%;
    margin: 0;
    background: color(white);
  }
}

.input {
  position: relative;
  width: 100%;

  input {
    width: 100%;
    height: 44px;
    padding: 0 35px;
    font-family: museo-sans-rounded, sans-serif;
    font-size: 14px;
    outline: 0;
  }

  .left_icon {
    position: absolute;
    top: 15px;
    left: 10px;
  }

  .right_icon {
    position: absolute;
    top: 14px;
    right: 14px;
  }
}