@import '../../../styles/base';

.image {
  align-items: center;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  overflow: hidden;

  &--dimension-height {
    img {
      max-height: 100%;
    }
  }

  &--dimension-width {
    img {
      max-width: 100%;
    }
  }

  &--full {
    img {
      max-height: 100%;
      max-width: 100%;
    }
  }

  // sizes
  &--xs {
    height: 32px;
    width: 32px;
  }

  &--sms {
    height: 64px;
    width: 64px;
  }

  &--sm {
    height: 74px;
    width: 74px;
  }

  &--md {
    height: 100px;
    width: 100px;
  }

  &--lg {
    height: 149px;
    width: 149px;
  }
}
