/* ---------- PRIMARY COLORS ---------- */

$bg:           #FAFAFA;
$dark-gray:    #8e8e8e;
$black:        #000;
$blue:         #597794;
$blue-active:  #345677;
$caption:      #999;
$deep-blue:    #001A29;
$green:        #74BF65;
$green-active: #549D46;
$grey:         #CCC;
$grey-light:   #F2F2F2;
$g-red:        #D0553E;
$g-orange:     #FCC745;
$g-green:      #73BD64;
$g-blue-light: #4FCCFF;
$g-blue:       #4D7BF3;
$g-blue-dark:  #1D4996;
$headline:     #333;
$hedge:        #C782BC;
$li-social:    #0077B5;
$line:         #E5E5E5;
$lilac-grey:   #88959F;
$red:          #C76458;
$red-active:   #AF5145;
$paragraph:    #545454;
$private:      #8EB7C1;
$venture:      #D6A76F;
$white:        #FFF;
$grey-text:    #5A626A;


/* ---------- DERIVATIVE COLORS ---------- */

$green-highlight: rgba($green, .1);
$green-line:      rgba($green, .2);
$red-highlight:   rgba($red, .1);
$red-line:        rgba($red, .2);


/* ---------- BOX-SHADOW STYLES ---------- */

$shadow: 0 1px 5px 0 rgba($black, .15);
$shadow2: 0 5px 15px 0 rgba($black, .1);
$shadow3: 0 10px 20px 0 rgba($black, .1);


/* ---------- BREAKPOINTS ---------- */

$breakpoints: (
  (mobile,  null,   599px),
  (tablet_p,  600px,  1023px),
  (tablet_l,  1024px,  1279px),
  (desktop, 1280px, null)
);


/* ---------- FUNCTIONS ---------- */

@function get-list-item($label, $list) {
  @each $list-item in $list {
    @if nth($list-item, 1) == $label {
      @return $list-item;
    }
  }
  @return null;
}


/* ---------- MIXINS ---------- */

@mixin breakpoint($breakpoint, $side: null) {
  $point-list: get-list-item($breakpoint, $breakpoints);
  $min-width: nth($point-list, 2);
  $max-width: nth($point-list, 3);
  @if $side == zero {
    $min-width: null;
  } @else if $side == infinity {
    $max-width: null;
  }
  @if $min-width and $max-width {
    @media only screen and (min-width: $min-width) and (max-width: $max-width) { @content; }
  } @else if $min-width {
    @media only screen and (min-width: $min-width) { @content; }
  } @else if $max-width {
    @media only screen and (max-width: $max-width) { @content; }
  }
}

/* ---------- PLACEHOLDERS: SCROLL ---------- */

%dm-scroll {
  ::-webkit-scrollbar {
    background-color: transparent;
    height: 5px;
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba($black, .05);
    border-radius: 2px;

    &:hover {
      background-color: rgba($black, .1);
    }

    &:active {
      background-color: rgba($black, .2);
    }
  }
}

/* ---------- PLACEHOLDERS: FLUID CONTAINER ---------- */

%fluid_container {
  display: flex;
  flex-direction: column;
  position: relative;
}

/* ---------- PLACEHOLDERS: CONTAINER ---------- */

$grid-column-width: 60px;
$grid-column-gutter: 20px;
$count-column-grid: 16;
$minimun-count-column: 4;

%container {
  max-width: 100%;
  margin: 0 auto;
  z-index: 100;

  @for $i from 0 through ($count-column-grid - $minimun-count-column) {
    $width: 300px + $i * ($grid-column-width + $grid-column-gutter);

    @media all and (min-width: $width) {
      width: $width;
    }
  }

  @include breakpoint(mobile) {
    min-width: 100%;
  }

  &.short {
    max-width: 780px;
  }
}
