@import '../FormControl/FormControl';

$url: url(../../../images/icons/icons_small.png);
$max-options: 5;
$option-height: 40px;
$value-height: 30px;
$value-margin: 6px;

.Select {
  $this: &;
  @include form-control('.Select-control', false);

  & & {
    &-control {
      line-height: $base-height - 2px;

      &:hover {
        box-shadow: none;
      }
    }

    &-placeholder {
      line-height: inherit;
      padding: 0 $base-padding;
    }

    &-multi-value-wrapper {
      line-height: inherit;
    }

    &-input {
      height: 100%;
      line-height: inherit;
      padding: 0;

      input {
        color: inherit;
      }
    }

    &-clear,
    &-arrow {
      line-height: 0;

      &-zone {
        line-height: inherit;
        padding: 0;
        text-align: right;
        vertical-align: bottom;

        &:hover {
          @extend .has-icons:hover;
        }
      }
    }

    &-clear {
      visibility: hidden;

      &:after {
        @include icon(select-clear, $url);
        visibility: visible;
      }

      &-zone {
        width: 14px;
      }
    }

    &-arrow {
      @include icon(select-arrow, $url);
      border: none;
      transition: transform 150ms;

      &-zone {
        width: 21px;
      }
    }
  }

  &.is-focused:not(.is-open) & {
    &-control {
      box-shadow: none;
    }
  }

  &.is-focused.is-searchable:not(.has-value) & {
    &-placeholder {
      visibility: hidden;
    }

    &-input {
      padding-left: 29px;
      position: relative;

      &:before {
        @extend %vertical-center;
        @include icon(magnifier, $url);
        left: 0;
      }

      input {
        padding: 0;
      }
    }
  }

  &.is-open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  &.is-open & {
    &-control {
      z-index: 30;
    }

    &-arrow {
      transform: rotate(180deg);
    }

    &-menu {
      border: none;
      border-radius: 0;
      border-top: 1px solid color(sbg);
      max-height: $max-options * $option-height + 1px;

      &-outer {
        border: $base-border;
        border-color: inherit;
        border-radius: $base-border-radius;
        border-top: none;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        box-shadow: none;
        box-sizing: content-box;
        margin-left: -$base-border-width;
        margin-top: 0;
        z-index: 20;
      }
    }

    &-option,
    &-noresults {
      align-items: center;
      background: none;
      border: none;
      border-top: 1px solid color(sbg);
      color: color(headings);
      display: flex;
      flex-direction: row;
      height: $option-height;
      padding: 0 $base-padding;

      &:first-child {
        border-top: none;
      }
    }

    &-option.is-focused {
      color: color(blue-m);
    }

    &__shadow {
      border-radius: $base-border-radius;
      bottom: 0;
      box-shadow: 0 0 10px color(gray-l);
      display: none;
      left: 0;
      margin: -$base-border-width;
      position: absolute;
      right: 0;
      top: 0;
      display: block;
      z-index: 15;
    }
  }

  &--single.has-value & {
    &-control {
      #{$this}-value {
        color: inherit;
        line-height: inherit;
        padding: 0 $base-padding;
        text-align: left;

        #{$this}-value-label {
          color: inherit;
        }
      }
    }
  }

  &--multi & {
    &-input {
      margin: 0;
    }
  }

  &--multi.has-value & {
    &-control {
      line-height: 1;
      padding-left: 0;
      padding-bottom: $value-margin;
    }

    &-value {
      align-items: center;
      background: color(blue-g);
      border: none;
      border-radius: 4px;
      color: color(gray-vl);
      display: inline-flex;
      font-size: 14px;
      margin: $value-margin 0 0 $value-margin;
      padding: 7px 10px;
      text-align: left;

      &-label {
        line-height: 16px;
        margin-right: 10px;
        order: 1;
        padding: 0;
        white-space: nowrap;
      }

      &-icon {
        @include icon(select-value-icon, $url);
        border: none;
        color: transparent;
        padding: 0;
        order: 2;
      }
    }

    &-input {
      height: $value-height;
      margin: $value-margin 0 0 $value-margin;
    }

    &-clear-zone,
    &-arrow-zone {
      line-height: $value-height;
    }
  }
}
