@import '../../../../styles/base';

.nav-modal {
  @include breakpoint(tablet) {
    flex: 1;
    height: 100vh;

    .modal__container {
      border-radius: 0;
    }
  }

  &__header {
    border: none;
    height: $header-fixed-height;

    @include breakpoint(tablet) {
      padding: 0 34px;
    }

    @include breakpoint(mobile) {
      padding: 0 30px;
    }

    &__logo {
      align-self: flex-start;
    }
  }

  &__body {
    align-items: center;
    background-color: color(white);
    display: flex;
    flex-direction: column;

    @include breakpoint(tablet) {
      flex: 1;
      overflow: auto;
    }
  }

  &__control-group {
    display: flex;
    justify-content: center;

    .btn {
      margin: 0 10px;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;

    &__item {
      margin-top: 35px;

      &__title {
        font-size: 18px;
        font-weight: 700;
        text-transform: uppercase;
      }
      &__links {
        display: flex;
        flex-direction: column;
        padding: 0 10px;

        .link {
          font-size: 16px;
          margin: 12px 0 12px -21px;
          padding-left: 10px;

          &:hover {
            border-left: 3px solid color(blue-m);
            padding-left: 7px;
          }
        }

        &:last-of-type {
          margin-top: 30px;
        }
      }
    }
  }
}
