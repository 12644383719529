@import '../../../styles/base';

.dots-spinner {
  width: 50px;
  height: 50px;

  &--light {
    circle {
      fill: color(white);
    }
  }

  &--dark {
    circle {
      fill: color(gray-d);
    }
  }
}
