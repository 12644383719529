@import '../variables';

.icon {
  fill: $headline;

  &.dark-gray {
    fill: $dark-gray;
  }

  &.blue {
    fill: $blue;
  }

  &.blue-active {
    fill: $blue-active;
  }

  &.green {
    fill: $green;
  }

  &.green-active {
    fill: $green-active;
  }

  &.red {
    fill: $red;
  }

  &.red-active {
    fill: $red-active;
  }

  &.white {
    fill: $white;
  }

  &.paragraph {
    fill: $paragraph;
  }

  &.caption {
    fill: $caption;
  }

  &.grey {
    fill: $grey;
  }

  &.line {
    fill: $line;
  }

  &.hedge {
    fill: $hedge;
  }

  &.private {
    fill: $private;
  }

  &.venture {
    fill: $venture;
  }
}
