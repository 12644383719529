@import '../../../styles/base';

.link {
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  text-decoration: none;

  & {
    color: color(blue-g);
  }

  &:hover {
    color: color(blue-m);
  }

  &--underline {
    text-decoration: underline;
  }
}
