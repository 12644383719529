@import '../../../styles/base';

.inbox {
  &__empty {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;

    &--offset {
      margin-top: -59px;
      position: relative;
      text-align: center;

      @include breakpoint(tablet, zero) {
        margin-top: 0;
      }
    }

    .icon-lg {
      display: flex;
      position: relative;
    }
  }
}
