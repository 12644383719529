@import '../variables';

.checkbox {
  align-items: flex-start;
  cursor: pointer;
  display: inline-flex;
  position: relative;
  margin-bottom: 10px;
  text-align: left;

  .label {
    color: $caption;
    font-size: 15px;
    margin-left: 12px;
    user-select: none;
  }

  .switch {
    align-items: center;
    background-color: $white;
    border: 1px solid $grey;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    height: 16px;
    position: relative;
    width: 16px;
    flex-shrink: 0;
    margin-top: 3px;

    svg {
      bottom: 0;
      margin: auto;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  &[disabled] {
    cursor: not-allowed;

    .switch {
      background-color: $bg;
    }

    .label {
      cursor: not-allowed;
    }
  }

  &.checked {
    .label {
      color: $paragraph;
    }

    .switch {
      background-color: $blue;
      border: 1px solid $blue;
    }
  }

  &[disabled].checked {
    cursor: not-allowed;

    .label {
      color: $caption;
      cursor: not-allowed;
    }

    .switch {
      background-color: $grey;
      border: 1px solid $grey;
    }
  }

  &.posCol {
    align-items: center;
    border: 1px solid $line;
    flex-direction: column-reverse;
    min-width: 125px;
    max-width: 240px;
    padding: 14px 20px 21px;

    label {
      margin: 0 0 9px;
      text-align: center;
    }

    &.checked {
      background-color: $blue;

      label {
        color: $white;
      }
    }

    &.checked[disabled] {
      background-color: $bg;
      border: 1px solid $line;
      cursor: not-allowed;

      .label {
        color: $caption;
        cursor: not-allowed;
      }

      .switch {
        background-color: $grey;
      }
    }
  }

  &.error {
    .switch {
      border-color: $red;
    }

    .errorMessage {
      bottom: 0;
      left: 0;
      position: absolute;
      transform: translateY(100%);
      width: 100%;
    }
  }
}
