@import '../../../styles/base';

.accordion {
  &__title {
    align-items: center;
    cursor: pointer;
    display: flex;

    .icon {
      flex-shrink: 0;
    }
  }

  &__content {
    display: none;
  }

  &--open & {
    &__content {
      display: inherit;
    }
  }
}
