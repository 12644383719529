@import '../../../styles/base';

.read-more {
  background-color: color(sbg);
  border-radius: 4px;
  margin-bottom: 25px;
  position: relative;

  &__title {
    background-image: linear-gradient(
      to top,
      color(sbg) 50%,
      rgba(color(sbg), 0.01)
    );
    bottom: 0;
    color: color(blue-g);
    display: flex;
    height: 78px;
    left: 0;
    justify-content: center;
    position: absolute;
    right: 0;
    text-transform: uppercase;

    span {
      bottom: 11px;
      position: absolute;
      left: 0;
      right: 0;
      text-align: center;
    }
  }

  &__content {
    display: block;
    height: 168px;
    overflow: hidden;
  }

  .accordion--open & {
    &__content {
      height: initial;
    }

    &__title {
      height: 48px;
      position: static;

      span {
        position: static;
      }
    }
  }
}
