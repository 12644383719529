@import "dm-public/ui-kit/_fonts";
@import "dm-public/ui-kit/minireset";
@import "dm-public/ui-kit/variables";
@import "dm-public/ui-kit/common";

/* ---------- TAGS: HTML ---------- */

html {
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

/* ---------- TAGS: BODY ---------- */

body {
  @extend %dm-scroll;
  color: $paragraph;
  background: $bg;
  font-family: museo-sans-rounded, sans-serif;
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  &[data-loading] {
    opacity: 0;
  }
}

/* ---------- TAGS: HEADINGS H1-H6 ---------- */

h1, h2, h3, h4, h5, h6 {
  color: $headline;
  font-family: acumin-pro, sans-serif;
  font-weight: bold;
  margin: 0;
}

$heading-sizes: (
  desktop: (
    h1: (56px, 72px),
    h2: (42px, 44px),
    h3: (24px, 44px),
    h4: (18px, 28px),
    h5: (15px, 24px),
    h6: (15px, 24px)
  ),
  tablet_l: (
    h1: (42px, 56px),
    h2: (36px, 52px),
    h3: (24px, 28px),
    h4: (18px, 28px),
    h5: (15px, 24px),
    h6: (15px, 24px)
  ),
  tablet_p: (
    h1: (42px, 56px),
    h2: (36px, 52px),
    h3: (24px, 28px),
    h4: (18px, 28px),
    h5: (15px, 24px),
    h6: (15px, 24px)
  ),
  mobile: (
    h1: (28px, 42px),
    h2: (24px, 38px),
    h3: (20px, 32px),
    h4: (18px, 28px),
    h5: (15px, 24px),
    h6: (15px, 24px)
  )
);

@each $breakpoint, $headings in $heading-sizes {

  @include breakpoint($breakpoint) {

    @each $heading, $sizes in $headings {

      $font-size: nth($sizes, 1);
      $line-height: nth($sizes, 2);
      #{$heading} {
        font-size: $font-size;
        line-height: $line-height;
      }
    }
  }
}

/* ---------- TAGS: PARAGRAPH ---------- */

p {
  font-size: 15px;
  line-height: 24px;
}
p[data-large] {
  font-size: 18px;
  line-height: 28px;
}

article,
aside,
figure,
footer,
header,
hgroup,
section {
  display: block;
}

/* ---------- TAGS: LINK ---------- */

//a {
//  color: $blue;
//  cursor: pointer;
//  font-size: 14px;
//  font-weight: 700;
//  line-height: 17px;
//  text-decoration: none;
//
//  &:hover {
//    color: $blue-active;
//    text-decoration: underline;
//  }
//}

/* ---------- TAGS: IMG ---------- */

//img {
//  max-width: 100%;
//}

/* ---------- TAGS: INPUT ---------- */

input[type='checkbox'],
input[type='radio'] {
  vertical-align: baseline;
}

label {
  color: $headline;
  display: block;
  font-size: 15px;
  line-height: 24px;
}

small {
  font-size: 0.8em;
}

strong {
  font-weight: 700;
}

/* ---------- TAGS: TABLE ---------- */

table {
  width: 100%;

  td,
  th {
    vertical-align: top;
  }

  th {
    color: #bbb;
  }
}

html,
body,
[id='app'] {
  height: 100%;
}

hr {
  border: none;
  display: block;
  height: 1px;
  margin: 1.5rem 0;
}

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 90px 0 0;

  @include breakpoint(desktop) {
    padding: 110px 0 0;
  }

  @include breakpoint(mobile) {
    padding: 80px 0 0;
  }
}

main {
  flex: 1;

  @include breakpoint(desktop) {
    padding: 0 40px;
  }

  @include breakpoint(tablet_l) {
    padding: 0 20px;
  }

  @include breakpoint(tablet_p) {
    padding: 0 20px;
  }

  @include breakpoint(mobile) {
    padding: 0 10px;
  }
}
