@import '../../../../styles/base';

.fund-tab-card {
  position: relative;
  border-radius: 4px;
  border: 1px solid color(gray);
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  flex-grow: 0;
  margin: 10px;
  width: 390px;
  padding: 20px;

  &:hover {
    border: 1px solid color(blue-m);
  }

  @include breakpoint(desktop) {
    min-width: 312px;
  }

  @include breakpoint(tablet, zero) {
    min-width: initial;
    width: 350px;
  }

  @include breakpoint(mobile) {
    width: 330px;
  }

  &__image {
    margin-right: 20px;
  }

  &__body {
    flex-grow: 1;
  }

  &__title {
    display: flex;
    margin-bottom: 12px;
    margin-top: -2px;
    position: relative;
  }

  &__name {
    font-size: 18px;
    line-height: 20px;
    max-width: 228px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @include breakpoint(tablet) {
      max-width: 214px;
    }

    @include breakpoint(mobile) {
      max-width: 168px;
    }
  }

  &--approved {
    .fund-tab-card__name {
      max-width: 208px;

      @include breakpoint(tablet) {
        max-width: 194px;
      }

      @include breakpoint(mobile) {
        max-width: 149px;
      }
    }
  }

  &__amount {
    align-items: center;
    background-color: color(blue-m);
    border-radius: 4px;
    color: color(white);
    display: inline-flex;
    font-size: 12px;
    height: 20px;
    justify-content: center;
    margin-left: 10px;
    padding: 0 4px;
    vertical-align: top;
  }

  &__content {
    margin-bottom: 10px;
  }

  &__content & {
    &__btn-primary {
      font-size: 14px;

      @include breakpoint(desktop) {
        font-size: 12px;
        height: 34px;
        min-height: 34px;
      }

      @include breakpoint(mobile) {
        font-size: 13px;
      }

      button {
        @include breakpoint(mobile) {
          padding: 0 13px;
        }
      }
    }
  }

  &__description {
    color: color(texts);
    display: flex;
    font-size: 12px;
    justify-content: center;
    line-height: 12px;
    margin-bottom: 9px;
    text-align: center;

    span {
      line-height: 15px;
      max-width: 155px;
    }
  }

  &__btn-icon {
    align-self: center;
    margin-right: 10px;
  }

  &__footer & {
    &__btn-secondary {
      font-size: 12px;
      min-height: 18px;
    }
  }

  &--invitation & {
    &__title {
      margin-bottom: 3px;
    }

    &__controls {
      display: flex;
      justify-content: space-between;
    }
  }

  &__icon {
    position: absolute;
    top: 5px;
    right: 5px;

    .icon {
      &:hover {
        opacity: 0.5;
      }
    }

    .dropdown-content {
      z-index: 1000;

      .dropdown-content__body {
        min-width: 170px;
      }
    }
  }
}
