@import '../../../styles/base';
@import './ModalParents';

.modal {
  border-radius: 4px;
  overflow: none;
  outline: none;
  padding: 0;
  position: relative;
  width: 770px;

  @include breakpoint(mobile) {
    height: 100%;
    width: 100%;
  }

  &__container {
    background: color(pbg);

    @include breakpoint(tablet, infinity) {
      border-radius: 4px;
    }

    @include breakpoint(tablet, zero) {
      display: flex;
      height: 100%;
      flex-direction: column;
    }
  }

  &__close {
    cursor: pointer;
    position: absolute;
    right: -26px;

    @include breakpoint(desktop, infinity) {
      @include icon(close-gray, url(../../../images/icons/icons_small.png));
    }

    @include breakpoint(tablet, zero) {
      @include icon(close-blue, url(../../../images/icons/icons_small.png));
      right: 23px;
      top: 27px;
      z-index: 1;
    }
  }

  &__header {
    align-items: center;
    border-bottom: 1px solid color(gray-vl);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100px;
    padding: 15px 0;

    @include breakpoint(mobile) {
      height: initial;
      padding: 22px 23px 17px;
    }

    &__title {
      color: color(blue-m);
      font-size: 24px;
      padding: 0 20px;
      text-align: center;

      @include breakpoint(mobile) {
        font-size: 18px;
      }
    }

    &__label {
      color: color(blue-g);
      font-size: 14px;
      text-align: center;
    }
  }

  &__body {
    background-color: color(blue-lg2);
    padding: 35px;

    @include breakpoint(mobile) {
      flex: 1;
      overflow: auto;
      padding: 35px 23px;
    }

    @include breakpoint(tablet, infinity) {
      &:last-child {
        border-radius: 4px;
      }
    }
  }

  &__controls {
    align-items: center;
    display: flex;
    justify-content: center;

    .btn {
      margin: 0 15px;
    }

    @include breakpoint(mobile) {
      &--mobile {
        .btn {
          margin: 0;

          &:first-child {
            button {
              padding-left: 0;
            }
          }
        }
      }
    }
  }

  &__footer {
    align-items: center;
    border-top: 1px solid color(gray-vl);
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    height: 100px;

    @include breakpoint(mobile) {
      height: initial;
      padding: 27px 23px;
    }
  }

  &--notification {
    @include breakpoint(tablet, infinity) {
      width: 590px;
    }
  }

  &--notification & {
    &__body {
      font-size: 15px;
      text-align: center;

      @include breakpoint(mobile) {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }

    &__controls {
      margin-top: 30px;
    }

    &__footer {
      .modal__controls {
        margin-top: 0;
      }
    }
  }
}
