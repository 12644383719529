@import '../../../../styles/base';

.potential-investment-amount-modal {
  @include breakpoint(mobile) {
    .modal__header__title {
      font-size: 17px;
    }
  }

  .form {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  &__body {
    align-items: center;
    display: flex;
    flex-direction: column;
    font-size: 15px;

    .form-group {
      &:last-of-type {
        margin-bottom: 0;
      }
    }

    &__text {
      text-align: center;
    }

    &__fund-name {
      align-items: center;
      color: color(texts);
      display: flex;
      justify-content: center;
    }

    &__container {
      margin-top: 30px;
      width: 292px;
    }
  }
}
