@import '../../../../styles/base';

$url: url(../../../../images/icons/icons_small.png);

.fund-card {
  background-color: color(sbg);
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  height: 180px;
  padding: 20px;
  width: 370px;

  @include breakpoint(tablet, zero) {
    height: 150px;
    width: 350px;
  }

  @include breakpoint(mobile) {
    width: 320px;
  }

  &__content {
    display: flex;
    margin-bottom: 2px;
  }

  &__avatar {
    .image {
      border-radius: 4px;
      display: flex;
      height: 100px;
      margin: 0 19px 10px 0;
      width: 100px;

      @include breakpoint(tablet, zero) {
        height: 74px;
        width: 74px;
      }
    }
  }

  &__state {
    align-items: center;
    color: color(cyan-s);
    display: flex;
    flex: 0 0 100px;
    justify-content: flex-start;
    padding-left: 22px;
    position: relative;

    @include breakpoint(mobile) {
      padding-left: 13px;
    }

    @include breakpoint(tablet) {
      font-size: 12px;
      margin-top: 10px;
    }

    div {
      @include icon(checked-arr, $url);
      left: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &__info {
    align-items: flex-start;
    display: flex;
    flex: 1;
    flex-direction: column;

    &__name {
      align-items: center;
      color: color(headings);
      display: flex;
      justify-content: space-between;
      font-size: 18px;

      div {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 181px;

        @include breakpoint(mobile) {
          width: 157px;
        }
      }

      span {
        align-items: center;
        background-color: color(blue-m);
        border-radius: 4px;
        color: color(white);
        display: flex;
        justify-content: center;
        font-size: 11px;
        height: 20px;
        margin-left: 10px;
        width: 20px;
      }
    }

    &__type {
      color: color(gray-d);
      font-size: 12px;
      margin-bottom: 18px;

      @include breakpoint(tablet, zero) {
        margin-bottom: 8px;
      }
    }

    // fund statuses
    &__status {
      margin-bottom: 16px;
      padding-left: 22px;
      position: relative;

      @include breakpoint(tablet, zero) {
        font-size: 12px;
        margin-bottom: 10px;
      }

      &__icon {
        left: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }

      &__label {
        display: flex;
        padding-right: 15px;
      }

      .tooltip {
        @extend %vertical-center;
        right: -15px;
      }

      // payment pending
      &--17 {
        color: color(orange);

        .fund-card__info__status__icon {
          @include icon(money-dollar, $url);
        }
      }

      // draft-requires-revision
      &--18,
      // update-requires-revision
      &--12 {
        color: color(red-error);

        .fund-card__info__status__icon {
          @include icon(eye, $url);
        }
      }

      // draft-update
      &--11 {
        color: color(orange);

        .fund-card__info__status__icon {
          @include icon(loading, $url);
        }
      }

      // draft-pending-approval
      &--16 {
        color: color(orange);

        .fund-card__info__status__icon {
          @include icon(clock, $url);
        }
      }

      // update-pending-approval
      &--9 {
        color: color(orange);

        .fund-card__info__status__icon {
          @include icon(loading, $url);
        }
      }

      // unpublished
      &--19 {
        color: color(texts);

        .fund-card__info__status__icon {
          @include icon(warning-gray, $url);
        }
      }
    }
  }

  &__controls {
    align-items: flex-end;
    display: flex;
    flex: 1;

    .btn {
      justify-content: flex-start;
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &--live#{&} {
    background-color: color(pbg);
    border: 1px solid color(gray);
    padding: 19px;
  }

  &--live & {
    &__controls {
      &__state {
        color: color(cyan-p);

        div {
          @include icon(mark-green, $url);
        }
      }
    }
  }

  &:hover {
    border-color: color(blue-m);
  }

  &:hover & {
    &__info {
      &__name {
        color: color(blue-m);
      }
    }
  }

  &:hover {
    border-color: color(blue-m);
  }

  &:hover & {
    &__info {
      &__name {
        color: color(blue-m);
      }
    }
  }

  &:hover {
    border-color: color(blue-m);
  }

  &:hover & {
    &__info {
      &__name {
        color: color(blue-m);
      }
    }
  }
}
