@import '../styles/base';
@import '../styles/vendor/fontFace';
//@include font-face(acumin-pro, '../styles/fonts/AcuminPro/AcuminPro-Regular', 400, null, woff);
//@include font-face(acumin-pro, '../styles/fonts/AcuminPro/AcuminPro-Italic', 400, italic, woff);
//@include font-face(acumin-pro, '../styles/fonts/AcuminPro/AcuminPro-Bold', 700, null, woff);
//@include font-face(acumin-pro, '../styles/fonts/AcuminPro/acumin-pro-italic-300', 300, italic, woff);
//@include font-face(museo-sans-rounded, '../styles/fonts/MuseoSansRounded/MuseoSansRounded-300', 300, null, woff);
//@include font-face(museo-sans-rounded, '../styles/fonts/MuseoSansRounded/MuseoSansRounded-700', 700, null, woff);
//@include font-face(museo-sans-rounded, '../styles/fonts/MuseoSansRounded/MuseoSansRounded-900', 800, null, woff);
//@include font-face(museo-sans-rounded, '../styles/fonts/MuseoSansRounded/MuseoSansRounded-1000', 900, null, woff);
@include font-face(pristina, '../styles/fonts/signature/pristina', null, null, ttf);
@include font-face(forte, '../styles/fonts/signature/forte', null, null, ttf);
@include font-face(rage, '../styles/fonts/signature/rage', null, null, ttf);


html,
body {
  color: color(texts);
  font-family: museo-sans-rounded, sans-serif;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Headings
@each $heading, $size in $heading-sizes {
  #{$heading} {
    color: color(headings);
    font-family: acumin-pro, sans-serif;
    font-size: $size;
    line-height: 1.2;
    margin: 0;
  }
}

h1,
h2,
h3,
h4 {
  //text-transform: uppercase;
}

a {
  text-decoration: none;

  & {
    color: inherit;
  }
}

strong {
  font-weight: bold;
}

@import '../styles/helpers';
