@import '../../../../styles/base';

.scroll-button {
  align-items: center;
  background-color: color(sbg);
  display: flex;
  height: 60px;
  justify-content: center;
  flex: 1;
  width: 100%;

  .btn {
    button {
      color: color(blue-g);
      font-size: 16px;
      font-weight: 300;
      text-transform: none;
    }
  }
}
