@import '../../../styles/base';

.link.header-nav-link {
  align-items: center;
  color: inherit;
  display: inline-flex;
  font-size: 14px;
  font-weight: 700;
  justify-content: center;
  height: 38px;
  position: relative;
  text-transform: uppercase;
  z-index: $header-z-index;

  @include breakpoint(desktop) {
    font-size: 12px;
  }

  &--active:before {
    border-bottom: 2px solid;
    content: '';
    position: absolute;
    bottom: 3px;
    width: 32px;
    left: 50%;
    margin-left: -16px;
  }

  &--has-amount:after {
    background-color: color(blue-m);
    border-radius: 2px;
    color: color(white);
    content: attr(data-amount);
    display: inline;
    font-size: 10px;
    justify-content: center;
    line-height: 1;
    padding: 2px 5px;
    position: absolute;
    top: -4px;
  }

  &--new:after {
    background-color: color(blue-m);
    border-radius: 3px;
    color: color(white);
    content: 'NEW';
    display: inline;
    font-size: 11px;
    justify-content: center;
    line-height: 1;
    padding: 3px 5px;
    position: absolute;
    top: 10px;
    left: -40px;
  }

  ~ .dropdown-content--bottom-center {
    bottom: 9px;
  }
}
