@import '../../../styles/base';

.checkable-group {
  align-items: flex-start;
  border: none;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;

  legend {
    margin-bottom: 13px;
  }

  &--error {
    legend span {
      color: color(red-error);
    }
  }

  &__asterisk {
    color: color(red-error);
  }
  &.button-group {
    .checkable {
      margin: 0;
      box-shadow: 0px 0px 9px 1px rgba(0, 0, 0, 0.2);
      &:first-child,
      &:first-child label {
        border-radius: 2px 0 0 2px;
      }
      &:last-child,
      &:last-child label {
        border-radius: 0px 2px 2px 0px;
      }
      &:hover label {
        background: color(sbg);
      }
      label {
        height: 40px;
        align-items: center;
        background: color(white);
        z-index: 1;
      }
      &.checkable--checked {
        box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2);
        label {
          background: color(blue-m);
        }
        label span {
          color: color(white);
        }
      }
    }
    .checkable--radio label:before,
    .checkable--radio.checkable--checked label:after {
      content: none;
    }
  }
}
