@import '../../../styles/base';

.inbox-message {
  display: flex;
  flex-direction: column;
  flex: 1;

  &__show-more {
    padding: 20px 0;
    text-align: center;
  }
}
