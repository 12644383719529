@import '../../../../../../styles/base';

.fund-show-risks {
  &__title {
    font-size: 24px;
    margin-bottom: 22px;
    text-align: center;
    text-transform: uppercase;

    @include breakpoint(tablet, zero) {
      color: color(headings);
      font-size: 18px;
      margin-bottom: 35px;
    }
  }

  p {
    color: color(texts);
    font-size: 12px;
    text-align: justify;
    a {
      text-decoration: underline;
    }
  }
}
