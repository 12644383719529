@import '../../../styles/base';

.form {
  label {
    color: color(texts);
    font-size: 14px;
  }

  &__controls {
    margin-top: 60px;
  }

  &--horizontal {
    .form-group {
      align-items: center;
      flex-flow: row;

      .form-control,
      .form-control-static {
        flex: 0 0 372px;
      }

      > label:first-child,
      &:after {
        content: '';
        flex: 1 1;
        margin: 0 29px;
      }
    }

    .form__controls {
      display: flex;
      flex-flow: row;
      justify-content: center;
    }
  }
}
