@import '../../../styles/base';

$row-height: 60px;
$row-height-header: 50px;

.table {
  display: flex;
  flex: 1;
  flex-direction: column;

  &__body {
    flex: 1;
    overflow: auto;
    -ms-overflow-style: none; // IE 10+
    overflow: -moz-scrollbars-none; // Firefox

    &::-webkit-scrollbar {
      display: none; // Chrome & Safari
    }

    &__empty {
      align-items: center;
      display: flex;
      flex-direction: column;
      padding-top: 112px;

      @include breakpoint(mobile) {
        padding: 112px 20px 0;
        text-align: center;
      }

      .icon-lg {
        margin-bottom: 14px;
        opacity: 0.5;
      }

      span {
        color: color(texts);
        font-size: 17px;

        @include breakpoint(desktop, zero) {
          font-size: 15px;
        }
      }
    }
  }

  &__row {
    border-bottom: 1px solid color(blue-l);
    display: flex;
    flex-wrap: wrap;
    font-size: 15px;
    height: $row-height;
    position: relative;

    &:not(&--header):hover:before {
      background: color(blue-m);
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 3px;
    }

    &:not(&--header):hover {
      .table-cell {
        color: color(headings);

        &__hidden {
          visibility: visible;
        }
      }
    }

    &:nth-child(2n-1):not(&--header) {
      background: color(gray-ls);
    }

    &--header {
      border-top: 1px solid color(blue-l);
      height: $row-height-header;

      .table-cell {
        color: color(headings);
        font-size: 12px;
        text-transform: uppercase;
      }
    }

    &--without-border {
      border: 0;

      .table-cell {
        border: 0;
      }
    }

    &--edit {
      .table-cell {
        &:last-child {
          justify-content: flex-start;
        }

        .input {
          flex: 0 0 255px;

          @include breakpoint(tablet, zero) {
            flex: 1;
          }
        }

        .btn:first-child {
          margin-right: 20px;
        }
      }
    }
  }
}
