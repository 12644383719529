@import '../../../styles/base';

.panel {
  background-color: transparent;
  display: none;
  flex-direction: column;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 99;

  &__content {
    background-color: rgba(color(pbg), 0.949);
    box-shadow: 0px 1px 8.5px 1.5px rgba(1, 2, 4, 0.2);
  }

  &--opened {
    display: flex;
  }

  &--with-overlay {
    bottom: 0;

    &:after {
      background-color: rgba(color(black), 0.502);
      content: '';
      flex: 1;
    }
  }
}
