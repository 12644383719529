@import '../variables';

.tabs {
  display: flex;
  flex: 1;
  flex-direction: column;

  .tabsPanel {
    background-color: $white;
    border: 1px solid $line;
    border-radius: 2px;
    display: flex;
    flex-shrink: 0;
    margin-bottom: 20px;
    overflow-y: hidden;
    overflow-x: auto;

    .tab {
      align-items: center;
      cursor: pointer;
      color: $headline;
      display: flex;
      height: 60px;
      font-size: 15px;
      font-weight: 600;
      flex-shrink: 0;
      justify-content: center;
      padding: 0 20px;
      position: relative;
      text-transform: uppercase;

      .dot {
        background-color: $grey;
        border-radius: 50%;
        height: 4px;
        margin-left: 2px;
        width: 4px;

        &:first-of-type {
          margin-left: 10px;
        }
      }

      .line {
        background-color: $blue;
        bottom: -1px;
        height: 2px;
        left: 0;
        right: 0;
        margin: auto;
        position: absolute;
        width: calc(100% - 20px);
      }

      .tabText {
        align-items: center;
        display: flex;
        justify-content: center;
        position: relative;
      }

      &:hover {
        color: $blue;

        .dot {
          background-color: $blue;
        }
      }

      &.active {
        color: $blue;
      }
    }

    .tabMore {
      margin-left: auto;
    }
  }

  &.tabs--secondary {
    .tabsPanel {
      background-color: transparent;
      border-width: 0 0 1px;
      margin-bottom: 0;
    }
  }
}
