@import '../../../../styles/base';

.activity-feeds {
  &__main {
    display: flex;
    flex-grow: 1;
    padding: 10px 23px 10px 0;

    @include breakpoint(mobile) {
      padding: 5px 0 0;
    }

    .activity-feeds__row-container {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }
  }

  &__row {
    &-container {
      overflow-y: auto;

      &--header {
        padding-right: 23px;
        overflow-y: visible;

        @include breakpoint(mobile) {
          padding: 0 20px;
        }
      }
    }
  }
}
