@import '../../../styles/base';

.input-range__slider {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: color(blue-m);
  border-radius: 100%;
  cursor: pointer;
  display: block;
  height: 15px;
  margin: -9px 0 0 -6px;
  outline: none;
  position: absolute;
  top: 50%;
  transition: -webkit-transform 0.3s ease-out, box-shadow 0.3s ease-out;
  transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
  width: 15px;

  &:active {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }

  &:focus {
    box-shadow: 0 0 0 5px rgba(63, 81, 181, 0.2);
  }

  &-container {
    transition: left 0.3s ease-out;
  }
}

.input-range__label--max .input-range__label-container {
  left: 50%;
}

.input-range {
  cursor: pointer;
  height: 1rem;
  position: relative;
  width: calc(100% - 12px);
  margin: 17px auto 0;

  &__label {
    display: none;
  }

  &__label {
    &--min {
      left: 0;
    }

    &--max {
      right: 0;
    }

    &--min,
    &--max {
      color: color(texts);
      font-weight: 700;
      font-size: 12px;
      position: absolute;
      top: 23px;
    }
  }

  &.is-disabled .input-range__track {
    background: #eeeeee;
  }

  &__label-container {
    left: -50%;
    position: relative;
  }

  &.is-disabled .input-range__slider {
    background: color(gray-l);
    border: 1px solid color(gray-l);
    box-shadow: none;
    -webkit-transform: none;
    transform: none;
  }

  &__track {
    background: #eeeeee;
    border-radius: 0.3rem;
    display: block;
    height: 0.3rem;
    position: relative;
    transition: left 0.3s ease-out, width 0.3s ease-out;

    &--container {
      left: 0;
      margin-top: -0.15rem;
      position: absolute;
      right: 0;
      top: 50%;
    }

    &--active {
      background: color(blue-m);
    }
  }
}
