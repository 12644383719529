@import '../../../../../../styles/base';

.fund-show-overview {
  margin-bottom: 60px;

  &__title {
    font-size: 24px;
    margin-bottom: 22px;
    text-align: center;
    text-transform: uppercase;

    @include breakpoint(tablet, zero) {
      color: color(headings);
      font-size: 18px;
      margin-bottom: 35px;
    }
  }

  &__content {
    display: flex;

    @include breakpoint(tablet, zero) {
      flex-direction: column;
    }
  }

  &__col {
    color: color(texts);
    display: flex;
    flex: 1;
    flex-direction: column;
    font-size: 15px;
  }

  &__sub__title {
    color: color(headings);
    font-size: 18px;
    margin-bottom: 10px;

    @include breakpoint(tablet, zero) {
      font-size: 16px;
      text-align: center;
    }
  }

  &__summary {
    @include breakpoint(desktop, infinity) {
      padding-right: 14px;
    }

    @include breakpoint(tablet, zero) {
      margin-bottom: 30px;
    }

    span {
      text-align: justify;
    }
  }

  &__highlights {
    @include breakpoint(desktop, infinity) {
      padding-left: 58px;
    }

    @include breakpoint(mobile) {
      padding-left: 30px;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;

    @include breakpoint(tablet) {
      padding-left: 30px;
    }

    &__item {
      color: color(texts);
      display: flex;
      margin-bottom: 14px;
      position: relative;

      span {
        display: flex;
        flex: 1;
      }

      &:before {
        @include icon(
          mark-success-gray,
          url(../../../../../../images/icons/icons_small.png)
        );
        left: -30px;
        margin-right: 14px;
        position: absolute;
        top: 5px;
      }
    }
  }
}
