@import '../../styles/base';

.inv-dashboard {
  display: flex;
  flex: 1;
  min-height: 100%;
}

.inv-dashboard-aside {
  &__btn-show-more-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  &__btn-show-more {
    button {
      align-items: center;
      display: flex;
    }

    .Icon {
      margin-right: 10px;
    }
  }

  .aside__list {
    .aside-link {
      margin-bottom: 5px;
      color: color(white);
      font-size: 14px;
      cursor: pointer;
    }
  }
}
