@import '../../../../../../styles/base';

.fund-show-investments {
  font-size: 14px;
  margin-bottom: 62px;

  @include breakpoint(mobile) {
    margin-bottom: 40px;
  }

  &__head {
    display: flex;
    margin-bottom: 6px;

    &__item {
      color: color(headings);

      @include breakpoint(tablet) {
        flex: 3;
        font-size: 12px;
        font-weight: 400;
      }

      @include breakpoint(mobile) {
        font-size: 12px;
        font-weight: 400;
      }

      &:first-child {
        display: flex;
        flex: 0 0 300px;

        @include breakpoint(tablet) {
          flex: 2;
        }
      }

      &:last-child {
        @include breakpoint(mobile) {
          display: none;
        }
      }
    }
  }

  &__row {
    color: color(texts);
    margin-bottom: 10px;

    @include breakpoint(tablet) {
      display: flex;
    }

    @include breakpoint(mobile) {
      display: flex;
      margin-bottom: 0;
    }

    &__content {
      display: flex;
    }

    &__investments {
      display: flex;
      flex: 0 0 262px;
      margin-right: 20px;
      padding: 13px 0 11px;

      @include breakpoint(tablet) {
        flex: 2;
      }

      @include breakpoint(mobile) {
        flex: auto;
        padding: 5px 0;
      }

      &:before {
        background-color: color(gray-l);
        border-radius: 50%;
        content: '';
        display: flex;
        flex: 0 0 6px;
        height: 6px;
        margin-right: 24px;
        position: relative;
        top: 7px;
        width: 6px;

        @include breakpoint(mobile) {
          display: none;
        }
      }
    }

    &__description {
      background-color: color(sbg);
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      flex: 0 0 647px;
      padding: 13px 20px 11px;

      @include breakpoint(tablet) {
        flex: 3;
      }

      @include breakpoint(mobile) {
        padding: 0;
      }
    }
  }

  &__description-title {
    color: color(headings);
    font-size: 12px;
    margin-bottom: 5px;
  }

  .accordion {
    flex: 1;
    margin: 0 -8px;
    padding: 8px 10px;

    &--open {
      @include breakpoint(mobile) {
        background-color: color(sbg);
        border-radius: 4px;
      }

      .accordion__title {
        .icon {
          transform: none;
        }
      }
    }

    &__title {
      flex-direction: row-reverse;

      .icon {
        transform: rotate(180deg);
      }
    }

    &__content {
      margin-top: 10px;
    }
  }
}
