@import '../../../styles/base';

.fund-manage-header-info {
  align-items: center;
  display: flex;
  overflow: hidden;

  &__fund-logo {
    height: 32px;
    margin-left: 20px;
    width: 32px;
  }

  &__fund-name {
    font-size: 18px;
    margin-left: 20px;
    margin-right: 40px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
