@import '../../../styles/base';

.regular-template {
  main {
    padding: 0;
    background: color(white);
  }

  .container {
    align-self: center;

    @include breakpoint(mobile) {
      width: map-get($container-width, mobile);
    }

    @include breakpoint(tablet) {
      width: map-get($container-width, tablet);
    }

    @include breakpoint(desktop, infinity) {
      width: map-get($container-width, desktop);
    }
  }

  &--old-container {
    .container {
      @include breakpoint(mobile) {
        width: map-get($old-container-width, mobile);
      }

      @include breakpoint(tablet) {
        width: map-get($old-container-width, tablet);
      }

      @include breakpoint(desktop, infinity) {
        width: map-get($old-container-width, desktop);
      }
    }
  }
}

.dashboard-template {
  flex-direction: row;
  height: 100vh;

  @include breakpoint(mobile) {
    height: 100%;
  }

  main {
    padding: 0;
    background: color(white);
    overflow: auto;
  }

  .container {
    flex: 1;
    min-height: calc(100vh - #{$header-fixed-height});
  }
}
