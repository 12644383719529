@import '../../../styles/base';

.tabs {
  display: flex;
  flex: 1;
  flex-direction: column;

  &__panel {
    border-bottom: 1px solid color(gray-vl);
    box-sizing: border-box;
    display: flex;
    flex-shrink: 0;
    overflow-x: auto;
    overflow-y: hidden;
    padding: 0 30px;

    @include breakpoint(mobile, zero) {
      padding: 0;
      width: 100vw;
    }
  }

  &__content {
    display: flex;
    flex: 1;
    flex-direction: column;

    &__empty {
      align-items: center;
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: center;
      min-height: 300px;
      text-align: center;

      .icon-lg {
        display: flex;
        margin-bottom: 25px;
        position: relative;

        @include breakpoint(desktop, zero) {
          margin-bottom: 15px;
        }
      }

      span {
        color: color(gray-d);
        font-size: 17px;
        margin-bottom: 25px;

        @include breakpoint(desktop, zero) {
          font-size: 15px;
          margin-bottom: 15px;
        }
      }
    }
  }
}
