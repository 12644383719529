$base-border-width: 1px;
$base-border-color: color(gray-l);
$base-border: $base-border-width solid $base-border-color;
$base-border-radius: 2px;
$base-color: color(headings);
$base-height: 44px;
$base-padding: 10px;
$base-icon-space: 28px;

$xs-height: 28px;

$sm-height: 38px;
$sm-icon-space: 23px;
$sm-padding: 10px;

$md-height: 48px;
$md-padding: 16px;

$lg-height: 58px;

$lgx-height: 74px;

$lgxx-height: 180px;
