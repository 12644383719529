@import '../../../../styles/base';

.investor-card {
  display: flex;
  justify-content: space-between;
  padding: 30px 25px 40px;

  @include breakpoint(desktop, zero) {
    padding-right: 25px;
  }

  @include breakpoint(mobile) {
    flex-direction: column;
    padding: 20px 20px 40px;
  }

  &__main {
    display: flex;
    flex-direction: column;
    min-width: 642px;

    @include breakpoint(desktop-mid-large) {
      min-width: 690px;
    }

    @include breakpoint(desktop, zero) {
      min-width: 430px;
    }

    @include breakpoint(mobile) {
      min-width: initial;

      .tag-group {
        margin: 25px 0 0;
      }
    }
  }

  &__header {
    display: flex;
  }

  &__avatar {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-right: 30px;

    .kyc-status-button {
      margin-top: 25px;

      &__label {
        font-size: 12px;
      }

      .icon {
        margin-right: 10px;
      }
    }
  }

  &__info {
    display: flex;
    justify-content: space-between;
    margin: 28px 0 26px;

    @include breakpoint(desktop, zero) {
      flex-direction: column;
    }

    &__row,
    &__col {
      .tag {
        margin: 0 4px 4px 0;

        @include breakpoint(desktop, zero) {
          margin-bottom: 0;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }

    &__row {
      display: flex;
      flex-wrap: wrap;

      &__item {
        font-size: 12px;
        flex: 1;
        margin-bottom: 13px;
      }

      &:first-child {
        @include breakpoint(mobile) {
          flex-direction: column;
        }
      }

      &:first-child & {
        &__item {
          > div:last-child {
            color: color(blue-g);
            font-family: acumin-pro;
            font-size: 20px;
            font-weight: bold;
          }

          &--green {
            div:last-child {
              color: color(cyan-p);
            }
          }
        }
      }
    }

    &__col {
      display: flex;
      flex: 1;
      flex-direction: column;
      margin-right: 40px;
      max-width: 320px;
      min-width: 225px;

      @include breakpoint(desktop-mid-large) {
        margin-right: 30px;
      }

      @include breakpoint(desktop, zero) {
        margin-right: 25px;
        min-width: initial;
        max-width: 192px;
      }

      &__item {
        padding-left: 33px;
        margin-bottom: 17px;

        > div:first-child {
          margin-bottom: 4px;
        }

        > div:last-child {
          align-items: center;
          color: color(blue-g);
          position: relative;

          .icon {
            content: '';
            left: -33px;
            position: absolute;
            top: 3px;
          }
        }

        &:first-child {
          height: 58px;
          margin-bottom: 37px;

          div:last-child {
            font-size: 30px;
            font-family: acumin-pro;
            font-weight: bold;
            white-space: nowrap;

            @include breakpoint(desktop-large) {
              font-size: 24px;
            }

            .icon {
              top: 16px;

              @include breakpoint(desktop-large) {
                top: 12px;
              }
            }
          }
        }

        &--green {
          div:last-child {
            color: color(cyan-p);
          }
        }
      }
    }
  }
  &__total-investment {
    margin-top: 25px;
    padding-left: 33px;
    position: relative;
    @include breakpoint(desktop, zero) {
      font-size: 12px;
    }
    @include breakpoint(mobile) {
      padding-left: 0px;
    }
    .icon {
      position: absolute;
      left: 0;
      top: 0;
    }
    .amount {
      font-size: 30px;
      color: #88959f;
      font-family: acumin-pro;
      font-weight: bold;
      white-space: nowrap;
      @include breakpoint(mobile) {
        font-size: 24px;
      }
    }
  }
  &__main-info {
    display: flex;
    flex: 1;
    flex-direction: column;

    @include breakpoint(mobile) {
      .kyc-status-button {
        margin-top: 12px;

        @include breakpoint(mobile) {
          margin-bottom: 10px;
          margin-top: 2px;
        }
      }
    }

    &__name {
      color: color(blue-m);
      display: flex;
      font-size: 24px;
      margin-top: -5px;

      @include breakpoint(desktop-large, infinity) {
        align-items: center;
        display: flex;
      }

      @include breakpoint(desktop, zero) {
        font-size: 18px;
      }
    }

    &__type {
      color: color(gray-d);
      font-size: 12px;
      margin-top: -5px;
    }

    .tag-group {
      margin-left: -10px;

      @include breakpoint(desktop, zero) {
        margin-left: -5px;
        margin-top: 15px;

        .tag {
          font-size: 12px;
          text-transform: capitalize;
        }
      }
    }
  }

  &--investor & {
    &__main {
      @include breakpoint(desktop, zero) {
        min-width: 600px;
      }

      @include breakpoint(mobile) {
        min-width: initial;
      }
    }

    &__avatar {
      margin-right: 20px;
    }
  }
}
