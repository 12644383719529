@import '../FormControl/FormControl';

$btn-diff-height: 4px;

@mixin btn-color($color-name) {
  $color: color($color-name);
  background-color: $color;

  &--flat {
    border-color: $color;
    color: $color;
  }
}

.btn {
  @include form-control(button, true, 14px);
  color: color(white);
  font-size: 17px;
  white-space: nowrap;

  button {
    cursor: pointer;
    display: block;
    font-weight: 700;
    text-transform: uppercase;
    transition: transform 70ms ease;
  }

  // animations
  &:focus,
  &:hover {
    opacity: 0.8;
  }

  &:active {
    transform: scale(0.96);
  }

  // colors
  &--blue {
    @include btn-color(blue-m);
    border-color: color(blue-border);
  }

  &--blue-dark2 {
    @include btn-color(blue-dark2);
  }

  &--blue-g {
    @include btn-color(blue-g);
  }

  &--cyan-p {
    @include btn-color(cyan-p);
  }

  &--green {
    @include btn-color(cyan-p);
    border-color: color(cyan-p);
  }

  &--red-s {
    @include btn-color(red-s);
    border-color: color(red-sd);
    color: color(white);
  }

  &--white {
    @include btn-color(pbg);
  }

  &--gray {
    @include btn-color(gray);
    border-color: color(gray);
  }

  &--linkedin {
    @include btn-color(linkedin);
    border-color: color(linkedin);
  }

  // states
  &--disabled {
    background-color: color(gray);
    border-color: color(gray);

    &:hover {
      opacity: 1;
    }

    button {
      cursor: not-allowed;
    }
  }

  &--flat {
    background-color: transparent;
    border-width: 2px;
  }

  &--flat#{&}--disabled {
    color: color(gray);
  }

  &--square {
    min-width: $base-height;
  }

  @include breakpoint(mobile) {
    &--mobile-text-wrap {
      border: none;
      color: color(blue-g);
      white-space: normal;

      button {
        font-size: 12px;
      }
    }
  }

  // sizes
  $this: &;

  &--sm {
    font-size: 14px;
  }

  &--md {
    @include size-padding($this, button, 25px);

    &-compact {
      @extend #{$this}--md;
      @include size-padding($this, button, 10px);
      min-width: 130px;
    }

    &-extend {
      @extend #{$this}--md;
      @include size-padding($this, button, 45px);
    }
  }

  &--xl {
    width: 180px;
  }

  &--xxl {
    width: 200px;
  }

  &--lg {
    width: 270px;
  }

  &--lgx {
    font-size: 18px;
  }

  &--lgxx {
    width: 370px;
  }

  @include breakpoint(desktop) {
    :not(&--frozen)#{&} {
      font-size: 15px;
      min-height: #{$base-height - $btn-diff-height};

      &--xs {
        font-size: 9px;
        min-height: #{$xs-height - $btn-diff-height};
      }

      &--sm {
        font-size: 12px;
        min-height: #{$sm-height - $btn-diff-height};
      }

      &--md {
        min-height: #{$md-height - $btn-diff-height};
      }

      &--lg {
        min-height: #{$lg-height - $btn-diff-height};
      }

      &--lgx {
        min-height: #{$lgx-height - $btn-diff-height};
      }

      &--lgxx {
        font-size: 13px;
        min-height: #{$lgxx-height - $btn-diff-height};
      }
    }
  }
}
