@import '../../../styles/base';

.tooltip {
  align-items: center;
  color: color(headings);
  display: inline-flex;
  margin: 0 3px;
  cursor: pointer;

  &--responsive {
    .dropdown-content {
      &--top-center {
        transform: translateY(-100%);
      }

      &--bottom-center {
        transform: translateY(100%);
      }
    }
  }

  .dropdown-content {
    &--top-center {
      @include breakpoint(mobile) {
        transform: translateY(-100%);
      }
    }

    &--bottom-center {
      @include breakpoint(mobile) {
        transform: translateY(100%);
      }
    }

    &__body {
      min-height: auto;
      min-width: 450px;
      padding: 17px 20px;

      @include breakpoint(mobile) {
        box-sizing: border-box;
        min-width: 334px;
        width: 334px;
      }
    }
  }

  ul {
    padding-left: 1em;
  }
}
