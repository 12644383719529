@import '../FormControl/FormControl';

.input {
  @include form-control(input);
  flex: 1;
  width: 100%;

  input {
    z-index: 0;

    &:-webkit-autofill,
    &:-webkit-autofill:focus {
      -webkit-box-shadow: 0 0 0 100px color(pbg) inset;
      -webkit-text-fill-color: color(gray-d);
    }

    &:disabled {
      -webkit-text-fill-color: color(gray-d);
    }
  }

  // states
  &#{&}--disabled {
    @extend %input-disabled;
  }
}
