@import '../../../styles/base';

.form-group {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 29px;
  position: relative;

  > label:first-child {
    margin-right: 29px;
    text-align: right;
  }

  &--hidden {
    visibility: hidden;
  }

  &--related {
    margin-top: -20px;
  }

  &--row-top {
    align-items: flex-start;
  }

  &--row-start {
    justify-content: flex-start;
  }

  &--col,
  &--col-center,
  &--col-right {
    flex-direction: column;
    align-items: stretch;

    > label:first-child {
      margin: 0 0 4px;
    }
  }

  &--col {
    > label:first-child {
      text-align: left;
    }
  }

  &--col-center {
    > label:first-child {
      text-align: center;
    }
  }

  &--col-right {
    > label:first-child {
      text-align: right;
    }
  }

  &__asterisk:after {
    color: color(red-error);
    content: '*';
  }
}
