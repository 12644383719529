@import '../../../styles/base';

.aside {
  display: flex;
  flex-direction: column;
  position: relative;
  width: $aside-width;
  z-index: $header-z-index;

  @include breakpoint(tablet, zero) {
    position: fixed;
    top: 0;
    bottom: 0;
    transform: translateX(-$aside-width);
    transition: transform 0.3s;

    &.open {
      transform: translateX(0);
    }
  }

  &__header {
    height: $header-fixed-height;
    left: 100%;
    top: -$header-fixed-height;
    position: absolute;
  }

  &__body {
    background: color(blue-aside);
    box-sizing: border-box;
    color: color(blue-m);
    min-height: 100%;
    overflow-y: auto;
    padding: 0 10px;
    flex-grow: 1;
  }

  &__group {
    margin-top: 36px;
    overflow-x: hidden;

    &__header {
      font-size: 12px;
      font-weight: 700;
      margin-bottom: 11px;
      margin-top: -3px;
      padding: 0 $aside-margin;
      text-transform: uppercase;

      &--white {
        color: color(white);
        font-size: 14px;
        font-weight: 300;
        margin-bottom: 7px;
        margin-top: -4px;
        text-transform: none;
      }
    }

    &--scrollable {
      display: flex;
      flex-direction: column;
      flex: 1;
      overflow-y: auto;
    }
  }

  &__list {
    overflow: auto;

    &__item {
      margin-bottom: 1px;
      padding: 0 $aside-margin;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
