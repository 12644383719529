@import '../variables';

.modal_cover {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000; // This must be at a higher index to the rest of your page content
  transform: translateZ(0);
  background-color: rgba(#000, 0.5);
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  //padding: 0 1.5em 1.5em 1.5em;
  background-color: $white;
  box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.1);
  overflow-y: auto;

  @include breakpoint(tablet_p, infinity) {
    left: 50%;
    top: 50%;
    height: auto;
    width: auto;
    transform: translate(-50%, -50%);
    max-height: calc(100% - 1em);
    overflow-y: inherit;
  }
}

.modal__close {
  position: absolute;
  top: 0;
  right: -30px;
  padding: 0;
  line-height: 1;
  background: transparent;
  border: 0;
  cursor: pointer;
  outline: 0;

  @include breakpoint(mobile) {
    top: 7px;
    right: 0;
    padding: .5em;
  }
}

.modal__close_icon {
  width: 25px;
  height: 25px;
  fill: transparent;
  stroke: $white;
  stroke-linecap: round;
  stroke-width: 2;

  &:hover {
    stroke-width: 3;
  }

  @include breakpoint(mobile) {
    stroke: $black;
  }
}

.modal__body {

}

.hide_visually {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}

.lock_scroll {
  overflow: hidden !important;
}