@import '../../../styles/base';

/* Remove scroll on the body when react-modal is open */
.ReactModal__Body--open {
  overflow: hidden;
}

.modal-portal {
  position: relative;
  z-index: 9999;
}

.modal-overlay {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999;

  &--scrollable {
    align-items: stretch;
    overflow: scroll;
  }
}
