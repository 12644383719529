@import '../../../styles/base';
@import './FormControlVariables';

@mixin form-control($form-field, $is-flex: true, $padding: $base-padding) {
  $this: &;
  @include size-padding(&, $form-field, $padding);
  align-items: stretch;
  background-color: color(pbg);
  border: $base-border;
  border-radius: $base-border-radius;
  box-sizing: border-box;
  color: $base-color;
  @if $is-flex {
    display: inline-flex;
    flex-direction: row;
  }
  font-size: 14px;
  font-weight: 300;
  min-height: $base-height;
  position: relative;

  #{$form-field} {
    appearance: none;
    background: transparent;
    border: none;
    box-sizing: border-box;
    color: inherit;
    @if $is-flex {
      display: flex;
      flex-grow: 1;
    }
    font: inherit;
    min-width: 0;
    outline: none;
    z-index: 10;
  }

  // icon
  &--icon {
    .icon {
      @extend %vertical-center;
    }
  }

  // helper
  &__helper {
    align-items: center;
    bottom: -#{$base-border-width + 2};
    display: flex;
    font-size: 12px;
    left: -$base-border-width;
    line-height: 1.15;
    position: absolute;
    transform: translateY(100%);
    visibility: hidden;
  }

  // states
  &--focus {
    border-color: color(gray-d);
  }

  &--async-validating {
  }

  &--error {
    border-color: color(red-error);
    color: color(gray-d);

    #{$this}__helper {
      color: color(red-error);
      visibility: visible;
    }
  }

  &--success {
    border-color: color(green-l);
    color: color(green-l);
  }

  &--xs {
    @include size-padding($this, $form-field, $sm-padding, $sm-icon-space);
    min-height: $xs-height;
    font-size: 11px;
  }

  &--sm {
    @include size-padding($this, $form-field, $sm-padding, $sm-icon-space);
    min-height: $sm-height;
  }

  &--md {
    min-height: $md-height;
  }

  &--lg {
    @include size-padding($this, $form-field, $md-padding);
    min-height: $lg-height;
  }

  &--lgx {
    min-height: $lgx-height;
  }

  &--lgxx {
    min-height: $lgxx-height;
    font-size: 15px;
  }
}

@mixin size-padding(
  $this,
  $form-field,
  $padding,
  $icon-space: $base-icon-space
) {
  //for lgxx
  $btn-lgxx-icon-space: 36px;
  $btn-lgxx-padding-top: 40px;
  $btn-lgxx-space-after-icon: 22px;
  $btn-lgxx-padding-bottom: 36px;
  $btn-lgxx-padding-horizontal: 114px;

  #{$form-field} {
    padding: 0 $padding;
  }

  &#{$this}--icon {
    @each $side in (left, right) {
      &--#{$side} {
        #{$form-field} {
          padding-#{$side}: #{$padding + $icon-space};
        }

        .icon {
          #{$side}: $padding;
        }
      }
    }

    &--center {
      .icon {
        @extend %center-center;
      }
    }

    &--top {
      #{$form-field} {
        padding-bottom: $btn-lgxx-padding-bottom;
        padding-left: $btn-lgxx-padding-horizontal;
        padding-right: $btn-lgxx-padding-horizontal;
        padding-top: #{$btn-lgxx-padding-top + $btn-lgxx-icon-space +
          $btn-lgxx-space-after-icon};
      }

      .icon {
        @extend %horizontal-center;
        top: $btn-lgxx-padding-top;
      }
    }
  }
}
