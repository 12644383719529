$dropdown: (
  list-item-size: 40px,
  triangle-size: 12px,
  shadow-size: 3px,
  icon-margin: 10px,
);

$dropdown: map-merge(
  $dropdown,
  (
    side-padding: map-get($dropdown, shadow-size) +
      map-get($dropdown, triangle-size),
    triangle-margin: map-get($dropdown, shadow-size) -
      map-get($dropdown, triangle-size),
    triangle-offset: map-get($dropdown, list-item-size) / 2 -
      map-get($dropdown, triangle-size),
    triangle-centering-size: map-get($dropdown, triangle-size) / 2,
    triangle-direction-size: map-get($dropdown, triangle-size) * 1.6,
  )
);
