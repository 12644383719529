@import '../../../../../styles/base';

.fund-show-modal {
  width: 1070px;

  @include breakpoint(desktop, infinity) {
    margin: 120px auto 0;
  }

  @include breakpoint(tablet, zero) {
    width: 100%;
  }

  @include breakpoint(mobile) {
    height: auto;
  }

  .modal__close {
    @include breakpoint(tablet) {
      top: 40px;
    }

    @include breakpoint(mobile) {
      top: 20px;
    }
  }

  &__overlay {
    @include breakpoint(tablet, zero) {
      top: 70px;
    }

    @include breakpoint(mobile) {
      overflow: auto;
    }
  }

  &__body {
    background-color: color(pbg);
    display: block;
    margin-bottom: 60px;
    padding: 41px 50px;
    text-align: left;

    @include breakpoint(tablet) {
      margin-bottom: 0;
      padding: 30px 60px;
    }

    @include breakpoint(mobile) {
      margin-bottom: 0;
      padding: 20px;
    }
  }

  .fund-show {
    & > *:last-child {
      margin-bottom: 0;
    }

    &__header {
      align-items: center;
      display: flex;
      margin-bottom: 18px;

      @include breakpoint(tablet, zero) {
        align-items: flex-start;
      }

      @include breakpoint(mobile) {
        flex-wrap: wrap;
        margin-bottom: 10px;
      }

      .image {
        margin-right: 31px;

        @include breakpoint(mobile) {
          margin-right: 10px;
        }
      }

      &__info {
        align-self: flex-start;
        display: flex;
        flex: 1;
        flex-direction: column;
      }

      .btn {
        align-self: flex-start;
        display: flex;
        margin-top: 25px;

        @include breakpoint(tablet) {
          margin-top: 0;
        }
      }
    }

    &__btns-zone {
      align-items: center;
      display: flex;
      flex-direction: column;
      width: 200px;
    }

    &__mark-support {
      background: transparent;
      display: flex;
      justify-content: center;
      text-align: center;

      span {
        color: color(texts);
        font-size: 12px;
        line-height: 15px;
        padding: 0 20px;
      }
    }

    &__btns-wrap {
      display: flex;

      .btn {
        margin-top: 5px;

        &:first-child {
          margin-right: 10px;
        }
      }

      span {
        right: -3px;
        position: relative;
      }
    }

    &__fund-name {
      color: color(blue-m);
      font-size: 24px;

      @include breakpoint(tablet, zero) {
        font-size: 18px;
        line-height: 1;
      }

      @include breakpoint(mobile) {
        padding-right: 25px;
      }
    }

    &__firm-name {
      color: color(gray-d);
      font-size: 15px;
      margin-bottom: 7px;

      @include breakpoint(tablet, zero) {
        font-size: 13px;
      }
    }

    &__eligible-and-type {
      display: flex;
      flex-wrap: wrap;

      @include breakpoint(tablet) {
        display: block;
        flex-wrap: nowrap;
      }
    }

    &__type {
      margin-bottom: 8px;

      @include breakpoint(tablet, zero) {
        flex-shrink: 0;
        font-size: 12px;
        height: 28px;
      }
    }

    .tag-fund-show__eligible-group {
      @include breakpoint(mobile) {
        flex: 100%;
      }
    }

    &__eligible {
      margin-bottom: 8px;

      @include breakpoint(tablet, zero) {
        font-size: 12px;
        line-height: 14px;
      }
    }

    &__btns {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 40px;
    }

    .bookmark-button {
      margin-top: 25px;
      margin-right: 25px;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }

      .dropdown-content__body {
        min-width: 210px;
      }

      @include breakpoint(tablet) {
        margin-top: 0;
      }

      @include breakpoint(mobile) {
        margin-top: 0;
      }
    }
  }
}
