@import '../variables';

.dropdown {
  .wrapper {
    position: relative;

    .toggler {
      color: inherit;
      cursor: pointer;
    }

    .list {
      background-color: $white;
      border: 1px solid $caption;
      border-radius: 2px;
      box-shadow: $shadow2;
      position: absolute;
      left: 0;
      bottom: 0;
      transform: translateY(100%);
      min-width: 140px;
      z-index: 1000;

      .option {
        align-items: center;
        color: $headline;
        cursor: pointer;
        display: flex;
        font-weight: bold;
        height: 44px;
        padding: 0 19px;
        text-transform: uppercase;
        transition: 0.2s;

        &:hover {
          background-color: $bg;
          color: $blue;
        }
      }
    }
  }
}
