@import '../../../styles/base';

.carousel {
  &.slick-slider {
    margin: 0 auto;

    .slick-track {
      align-items: center;
      display: flex;

      .slick-slide {
        color: color(texts);
      }
    }
  }

  &__arrow {
    @extend %vertical-center;
    @include icon(
      carousel-arrow-sm,
      url(../../../images/icons/icons_small.png)
    );
    cursor: pointer;

    &.slick-prev {
      left: 0;
      transform: translateY(-50%) rotateY(180deg);
    }

    &.slick-next {
      right: 0;
    }
  }
}
