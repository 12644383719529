@import '../../../../styles/base';

.slick-dots {
  font-size: 0;
  list-style-type: none;
  padding-left: 0;
  text-align: center;

  li {
    display: inline-block;
    margin: 0 6px;
    &.slick-active button {
      background: color(blue-m);
    }
  }

  button {
    background: color(gray-vl);
    border-radius: 50%;
    border: none;
    cursor: pointer;
    outline: none;
    padding: 0;
    height: 10px;
    width: 10px;
  }
}

.asset-managers {
  padding-top: 40px;

  &-carousel {
    position: relative;

    .slick-dots {
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }

    .slick {
      &-slider {
        .slick-track {
          align-items: flex-start;
        }
      }

      &-slide {
        align-items: center;
        display: inline-flex;
        filter: grayscale(100%);
        flex-direction: column;
        font-size: 17px;
        font-weight: 300;
        justify-content: center;
        line-height: 24px;
        opacity: 0.6;
        position: relative;
        transition: all 1111ms ease;
        z-index: 3;

        &.slick-center {
          filter: grayscale(0);
          opacity: 1;

          .asset-managers__text {
            background: color(sbg);
            animation-delay: 0s;
            animation-direction: normal;
            animation-duration: 1.5s;
            animation-iteration-count: 1;
            animation-name: blinkAssets;
            animation-timing-function: linear;
            opacity: 1;
            top: 230px;
          }

          .asset-managers__person {
            background: color(sbg);
            animation-delay: 0s;
            animation-direction: normal;
            animation-duration: 1050ms;
            animation-iteration-count: 1;
            animation-name: fadeInBackground;
            animation-timing-function: linear;
          }
        }
      }

      &-prev,
      &-next {
        background: transparent;
        height: 80px;
        opacity: 0.6;
        position: absolute;
        top: 78px;
        transition: background 0.1s, opacity 0.1s;
        width: 50px;

        @include breakpoint(mobile) {
          height: 40px;
          top: 95px;
          width: 25px;
          z-index: 1;

          &:before {
            left: 5px;
            top: 15px;
          }
        }

        &:before,
        &:after {
          background: color(blue-m);
          content: '';
          display: flex;
          height: 4px;
          position: absolute;
          transition: background 0.2s;
          width: 25px;

          @include breakpoint(mobile) {
            height: 3px;
            width: 13px;
          }
        }

        &:before {
          left: 11px;
          top: 30px;
          transform: rotate(-45deg);

          // @include breakpoint(mobile) {
          //   left: 5px;
          //   top: 15px;
          // }
        }

        &:after {
          left: 12px;
          top: 46px;
          transform: rotate(45deg);

          @include breakpoint(mobile) {
            left: 5px;
            top: 22px;
          }
        }

        &:hover {
          background: color(blue-m);
          opacity: 1;

          &:before,
          &:after {
            background: color(pbg);
            content: '';
          }
        }
      }

      &-prev {
        left: -27px;
        transform: rotate(0deg);

        @include breakpoint(tablet) {
          left: 50px;
        }

        @include breakpoint(mobile) {
          left: 0;
        }

        .carousel__arrow__helper {
          right: -343px;
        }
      }

      &-next {
        right: -27px;
        transform: rotate(-180deg);

        @include breakpoint(tablet) {
          right: 50px;
        }

        @include breakpoint(mobile) {
          right: 0;
        }

        .carousel__arrow__helper {
          left: -343px;
        }
      }
    }
  }

  &__person {
    border-radius: 5px 5px 0 0;
    display: flex;
    padding: 30px 45px 60px;
    box-sizing: border-box;
    width: 310px;

    @include breakpoint(mobile) {
      justify-content: center;
      padding-bottom: 0;
      width: 100%;
    }
  }

  &__info {
    margin: 3px 0 6px 18px;
    max-width: 135px;
    min-width: 135px;
    position: relative;
    text-align: left;
    z-index: 3;
  }

  &__name {
    color: color(blue-m);
    font-size: 20px;
    font-weight: 700;
    line-height: 21px;
    margin: -2px 0 4px;
  }

  &__position {
    color: color(gray-d);
    font-size: 12px;
    font-weight: 300;
    line-height: 15px;
    position: absolute;
  }

  &__url {
    position: relative;
    top: 26px;
    z-index: 4;

    a {
      color: color(blue-g);
      font-size: 12px;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .icon {
    margin-right: 6px;
    position: relative;
    top: 5px;
  }

  &__text {
    border-radius: 5px;
    opacity: 0;
    padding: 30px;
    width: 910px;
    z-index: 2;
    transition: background 1500ms;

    @include breakpoint(tablet, zero) {
      box-sizing: border-box;
      width: 100%;
    }

    @include breakpoint(mobile) {
      border-radius: 0;
      font-size: 15px;
      padding: 20px;
    }

    &__title {
      color: color(headings);
      margin-top: 0;

      @include breakpoint(mobile) {
        font-size: 16px;
        text-align: center;
      }
    }
  }

  .carousel__arrow__helper {
    height: 73px;
    left: -343px;
    position: absolute;
    top: 184px;
    width: 260px;
    z-index: 2;
  }

  > .avatar-image {
    margin-left: 35px;
  }
}

@keyframes blinkAssets {
  0% {
    opacity: 0;
    top: 240px;
  }
  10% {
    top: 230px;
  }
  50% {
    opacity: 1;
  }
}

@keyframes fadeInBackground {
  0% {
    background: color(white);
  }
  100% {
    background: color(sgb);
  }
}
