@import '../../../styles/base';

$checkbox-size: 18px;
$radio-size: 14px;

.checkable {
  input {
    display: none;
  }

  label {
    color: inherit;
    cursor: pointer;
    display: flex;
    padding-left: $checkbox-size;
    position: relative;
    user-select: none;

    &:before {
      background: transparent;
      border: 2px solid color(blue-l);
      box-sizing: border-box;
      content: '';
      left: 0;
      top: 0;
      position: absolute;
    }

    &:after {
      top: 4px;
    }
  }

  &--checked {
    color: color(blue-m);

    label:before {
      border-color: color(blue-m);
    }
  }

  // types
  &--checkbox {
    label {
      min-height: $checkbox-size;

      span {
        // padding-left: 27px;
        padding-left: 10px;

        @include breakpoint(tablet) {
          padding-left: 22px;
        }

        @include breakpoint(mobile, zero) {
          padding-left: 8px;
        }
      }

      &:empty {
        height: $checkbox-size;
        padding-left: $checkbox-size;
      }

      &:before {
        border-radius: 1px;
        height: $checkbox-size;
        width: $checkbox-size;
      }
    }
  }

  &--checkbox#{&}--checked {
    label {
      &:before {
        background: color(blue-m);
      }

      &:after {
        @include icon(checked-md, url(../../../images/icons/icons_small.png));
        left: 3px;
        position: absolute;
      }
    }
  }

  &--radio {
    label {
      &:empty {
        height: $radio-size;
        padding-left: $radio-size;
      }

      &:after {
        top: 7px;
      }

      &:before {
        border-radius: 50%;
        height: $radio-size;
        top: 3px;
        width: $radio-size;
      }
    }
  }

  &--radio#{&}--checked {
    label {
      &:after {
        background-color: color(blue-m);
        border-radius: 50%;
        content: '';
        height: 6px;
        left: 4px;
        position: absolute;
        width: 6px;
      }
    }
  }

  // states
  &--disabled {
    color: color(gray-d);

    label {
      cursor: not-allowed;

      &:before {
        background: color(sbg);
        border-color: color(gray-vl);
      }
    }
  }

  &--disabled#{&}--checked {
    label:before {
      background: color(gray);
      border-color: color(gray);
    }
  }

  &--error,
  &--error label {
    color: color(red-error) !important;
  }
}
