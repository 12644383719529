@import '../../../styles/base';

.activity-feeds {
  background: color(sbg);
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: $activity-feeds-height;

  &--small & {
    &__sidebar {
      flex: 0 0 86px;

      &-row {
        padding-left: 23px;
        position: relative;

        &--custom {
          margin-top: 40px;

          &:first-child {
            margin-top: 27px;
          }
        }
      }

      &-month {
        text-transform: uppercase;
      }
    }
  }

  &__container {
    background: #f2f2f2;
    display: flex;
    flex: 1;
    position: relative;
    height: 100%;
  }

  .btn--white {
    @extend %horizontal-center;
    bottom: 18px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.4);
    color: color(blue-g);
    font-size: 14px;

    &:active {
      transform: translateX(-50%);
    }

    &:hover {
      opacity: 1;

      button {
        opacity: 0.8;
      }
    }
  }

  &--empty {
    background: color(pbg);

    .activity-feeds {
      &__sidebar,
      &__header {
        display: none;
      }

      &__main {
        left: 0;
        position: relative;
        top: 0;
        display: flex;
        flex-grow: 1;
      }

      &__empty-box {
        display: flex;
        flex-direction: column;
      }
    }

    span {
      color: color(gray-d);
      font-size: 17px;

      @include breakpoint(desktop, zero) {
        font-size: 15px;
      }
    }
  }
}
