@import '../../../../../../styles/base';

.eaf.fund-container__r-col__footer {
  padding: 0 25px;
  @include breakpoint(mobile) {
    padding: 0;
  }
  .fund-container__descs {
    padding: 0;
    min-width: 100%;
  }
  .fund-container__chars {
    padding: 0;
    min-width: 100%;
  }
}

.fund-show-info {
  display: flex;
  flex-direction: row;
  margin-bottom: 60px;
  &.eaf {
    margin-bottom: 20px;
  }

  @include breakpoint(tablet, zero) {
    flex-direction: column;
  }

  @include breakpoint(mobile) {
    margin-bottom: 20px;
  }

  &.eaf {
    flex-direction: column;

    .eaf-tooltip {
      background: #f8f8f8;
      margin-bottom: 30px;
      padding: 20px 20px 20px 60px;
      color: #333333;
      position: relative;

      svg {
        position: absolute;
        left: 15px;
        top: 20px;
        width: 32px;
        height: 32px;
      }

      .eaf-tooltip-title {
        font-weight: 700;
        font-size: 15px;
        margin-bottom: 15px;
      }
    }

    .fund-show-info__fund-aum {
      border-bottom: none !important;
      padding-top: 15px;
      @include breakpoint(mobile) {
        padding-top: 0;
      }
    }
  }

  &__item {
    display: flex;
    flex: 1;
    flex-wrap: wrap;

    .eaf & {
      &:last-child {
        border-top: 1px solid color(sbg);
      }
      border-left: none !important;

      .fund-show-info__fund-other {
        padding-top: 14px;
      }
    }

    &:not(.eaf) .fund-show-info__fund-aum {
      @include breakpoint(desktop, infinity) {
        width: 100%;
      }
    }

    &--mobile {
      @include breakpoint(mobile) {
        border-top: 1px solid color(sbg);
        border-bottom: 1px solid color(sbg);
        padding: 20px 0;
      }
    }

    &:last-child {
      @include breakpoint(desktop, infinity) {
        border-left: 1px solid color(sbg);
      }
    }
  }

  &__row {
    @include breakpoint(desktop, infinity) {
      display: flex;
    }

    @include breakpoint(tablet) {
      margin-bottom: 20px;
    }

    &:last-child {
      margin-bottom: 0;

      @include breakpoint(mobile) {
        margin-bottom: 20px;
      }
    }

    &--fund-strategy {
      flex: 100%;

      @include breakpoint(mobile) {
        margin-bottom: 15px;
      }
    }

    &--redeption-period {
      margin-right: 40px;

      @include breakpoint(desktop, infinity) {
        flex: 100%;
        margin-right: 0;
      }
    }

    &__label {
      font-size: 14px;
      margin-right: 8px;

      @include breakpoint(desktop, infinity) {
        align-items: center;
        display: flex;
        flex: 0 0 132px;
        height: 32px;
        justify-content: flex-end;
      }

      @include breakpoint(tablet, zero) {
        font-size: 12px;
      }
    }

    &__tags {
      .tag {
        margin: 0 10px 13px 0;

        @include breakpoint(tablet, zero) {
          font-size: 10px;
          height: 18px;
          margin: 0 5px 0 0;
          padding: 0 5px;
          text-transform: uppercase;
        }
      }
    }
  }

  &__fund-aum {
    border-bottom: 1px solid color(sbg);
    color: color(blue-g);
    padding: 0 12px 15px 30px;

    @include breakpoint(tablet) {
      flex: auto;
      flex-shrink: 0;
      font-size: 12px;
      margin-right: 40px;
      padding: 32px 0 0;
      border-bottom: none;
    }

    &__size {
      color: color(cyan-p);
      font-size: 32px;
      font-weight: 600;

      @include breakpoint(tablet, zero) {
        font-size: 20px;
      }
    }
  }

  &__fund-other {
    color: color(blue-g);
    display: flex;
    flex: 1;
    justify-content: space-between;
    padding: 18px 12px 0 30px;

    @include breakpoint(tablet, zero) {
      flex: 20;
      font-size: 12px;
      padding: 32px 0 0;
    }

    &__attr {
      &__value {
        font-size: 28px;
        font-weight: 600;

        @include breakpoint(tablet, zero) {
          font-size: 20px;
        }
      }
    }
  }

  &--column {
    flex-direction: column;
  }

  &--column & {
    &__row {
      @include breakpoint(tablet, zero) {
        margin-bottom: 15px;
      }

      &:last-child {
        @include breakpoint(tablet) {
          margin-bottom: 13px;
        }

        @include breakpoint(mobile) {
          margin-bottom: 20px;
        }
      }
    }

    &__item {
      flex-direction: column;
      flex-wrap: nowrap;

      &:last-child {
        border-left: 0;
        flex-direction: row;
      }
    }

    &__fund-aum {
      border-top: 1px solid color(sbg);
      border-bottom: 0;
      padding: 32px 0 0;
      flex: 0 0 310px;

      @include breakpoint(tablet) {
        flex: auto;
        flex-shrink: 0;
        font-size: 12px;
        margin-right: 20px;
      }

      &__size {
        font-size: 28px;

        @include breakpoint(tablet, zero) {
          font-size: 20px;
        }
      }
    }

    &__fund-other {
      border-top: 1px solid color(sbg);
      flex: 1;
      padding: 32px 0 0;

      @include breakpoint(tablet, zero) {
        flex: 20;
      }

      &__years {
        font-size: 14px;
        text-transform: uppercase;
      }
    }
  }

  &__l-col {
    flex: auto;
    margin-right: 45px;
    max-width: 200px;
  }

  &__l-col & {
    &__fund-aum {
      border: none;
      padding: 0;
    }

    &__fund-other {
      border: none;
      padding: 0;
    }
  }
}
