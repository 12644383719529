@import '../../styles/base';

.kyc {
  .background {
    display: flex;
    justify-content: center;
    width: 100%;
    background: color(sbg);
  }

  main {
    align-items: center;
    display: flex;
    flex-direction: column;
    position: relative;

    .container {
      padding: 50px 0 60px;
    }

    @include breakpoint(tablet, zero) {
      .container {
        padding: 50px 0 0;
        width: 100%;
      }
    }
  }

  &__header {
    align-items: center;
    display: flex;
    flex-direction: column;

    @include breakpoint(tablet, zero) {
      padding: 0 20px;
    }
  }

  &__title {
    color: color(blue-m);
    font-size: 36px;
    margin-bottom: 40px;
    position: relative;
    text-align: center;

    @include breakpoint(tablet, zero) {
      font-size: 24px;
    }

    &::after {
      @extend %horizontal-center;
      bottom: -20px;
      content: '';
      display: block;
      background-color: color(blue-m);
      height: 2px;
      width: 70px;
    }
  }

  &__description {
    margin: 0 0 30px;
    max-width: 905px;
    text-align: center;

    @include breakpoint(mobile) {
      font-size: 12px;
      margin-bottom: 25px;
    }
  }

  &__body {
    background-color: color(white);
    border-radius: 5px;
    box-sizing: border-box;
    box-shadow: 0 1px 3px color(gray-vl);
    margin: 30px 0 60px;
    padding: 60px 70px;
    position: relative;

    @include breakpoint(tablet, zero) {
      align-items: center;
      border-radius: 0;
      display: flex;
      flex-direction: column;
      padding: 32px 0 77px;
    }
  }

  &__controls {
    display: flex;
    justify-content: center;
    margin-top: 62px;
    min-width: 100%;
  }

  .scroll-button {
    bottom: 0;
    position: absolute;
  }
}
