$header-fixed-height: 70px;
$header-absolute-height: 100px;
$header-z-index: 999;
$aside-width: 220px;
$aside-margin: 10px;
$activity-feeds-height: 300px;

$layout-width: (
  mobile: 100%,
  tablet: 768px,
  desktop: 1200px
);

$container-width: (
  mobile: 300px,
  tablet: 700px,
  desktop: 1100px
);

$old-container-width: (
  mobile: 340px,
  tablet: 726px,
  desktop: 1170px
);

$breakpoint-list: (
  (mobile,            null,   767px),
  (tablet,            768px,  1199px),
  (desktop,           1200px, 1439px),
  (desktop-large,     1440px, 1599px),
  (desktop-mid-large, 1600px, 1919px),
  (desktop-hd,        1920px, null)
);

$heading-sizes: (
  h1: 60px,
  h2: 32px,
  h3: 28px,
  h4: 24px,
  h5: 20px,
  h6: 18px
);

$colors: (
  black:           #000,
  blue-aside:      #23303e,
  blue-aside-link: #aec8da,
  blue-border:     #4f708f,
  blue-dark:       #031820,
  blue-dark2:      #001a29,
  blue-d:          #55667d,
  blue-dm:         #00385e,
  blue-g:          #88959f,
  blue-l:          #d3e4f0,
  blue-lg:         #e4e7e9,
  blue-lg2:        #f9f9fc,
  blue-light:      #d7e3ef,
  blue-light2:     #1a3351,
  blue-light3:     #e7eaec,
  blue-light4:     #c3cacf,
  blue-m:          #617f9c,
  blue-name:       #3b5465,
  cyan:            #8eb7c1,
  cyan-p:          #00da83,
  cyan-s:          #01cb7a,
  gray:            #bbb,
  gray-l:          #ccc,
  gray-d:          #999,
  gray-ls:         #f6f9fb,
  gray-vl:         #e5e5e5,
  green-d:         #78bb85,
  green-h:         #54bb9f,
  green-l:         #95c261,
  green-lm:        #4ebb39,
  green:           #549D46,
  green-light:    #70A961,
  headings:        #333,
  linkedin:        #0077b5,
  orange:          #d6a76f,
  red:             #aa0015,
  red-error:       #e2675a,
  red-s:           #ea8b7f,
  red-sd:          #d17d72,
  pbg:             #fff,
  sbg:             #fafafa,
  texts:           #707070,
  texts2:           #888,
  transparent:     transparent,
  violet:          #7c82bc,
  white:           #fff,
  darkviolet:      #9D6582
);

$icons-sm: (
  // name:               (width, height, static-position, hover-position)
  plane-white:           (17,    16,     -66px -67px),
  plane:                 (17,    16,     -66px -167px),
  social:                (32,    32),
  social--twitter:       (null,  null,   -109px -59px,    -109px -159px),
  social--facebook:      (null,  null,   -159px -59px,    -159px -159px),
  social--linkedin:      (null,  null,   -209px -59px,    -209px -159px),
  social--youtube:       (null,  null,   -259px -59px,    -259px -159px),
  linkedin:              (18,    18,     -316px -66px),
  linkedin-white:        (18,    18,     -316px -166px),
  nav-burger-white:      (20,    16,     -365px -67px),
  nav-burger-blue:       (20,    16,     -365px -167px),
  close-gray:            (16,    16,     -416px -67px,    -416px -167px),
  hamburger:             (25,    32,     -461px -58px,    -461px -158px),
  exit:                  (25,    35,     -511px -59px,    -511px -159px),
  carousel-arrow-lg:     (18,    32,     -565px -59px,    -565px -159px),
  delete:                (14,    14,     -617px -68px,    -617px -168px),
  carousel-arrow-sm:     (14,    25,     -669px -62px,    -669px -162px),
  select-arrow:          (10,    5,      -770px -73px,    -770px -173px),
  select-arrow--active:  (10,    5,      -770px -123px),
  close:                 (16,    16,     -817px -67px,    -817px -167px),
  select-clear:          (9,     9,      -820px -71px,    -820px -171px),
  arrow-right:           (5,     10,     -873px -70px,    -873px -170px),
  edit-v3:               (18,    18,     -916px -66px,    -916px -166px),
  arrow-bold-sm:         (13,    7,      -968px -72px,    -968px -172px),
  download:              (13,    16,     -1018px -67px,   -1018px -167px),
  linkedin-sm:           (16,    16,     -1067px -67px,   -1067px -167px),
  user-pic:              (22,    16,     -1114px -67px,   -1114px -167px),
  filter:                (21,    16,     -1164px -67px,   -1164px -167px),
  aside-permissions:     (18,    14,     -1216px -68px,   -1216px -168px),
  aside-contacts:        (17,    15,     -1267px -68px,   -1267px -168px),
  aside-browse:          (16,    16,     -1317px -67px,   -1317px -167px),
  aside-pipeline:        (17,    15,     -1367px -67px,   -1367px -167px),
  aside-dashboard:       (19,    19,     -3007px -166px,   -3007px -67px),
  preview:               (16,    16,     -1467px -67px,   -1467px -167px),
  manage:                (12,    16,     -1519px -67px,   -1519px -167px),
  edit-v2:               (17,    18,     -1567px -66px,   -1567px -166px),
  scroll-to-top:         (17,    17,     -1616px -67px,   -1616px -167px),
  social--twitter-full:  (24,    21,     -1663px -65px,   -1663px -165px),
  social--facebook-full: (7,     22,     -1722px -64px,   -1722px -164px),
  social--linkedin-full: (21,    21,     -1765px -65px,   -1765px -165px),
  t-lines-sm:            (13,    9,      -1869px -70px,   -1869px -170px),
  arrow-check:           (17,    16,     -1967px -71px,   -1967px -171px),
  check:                 (17,    16,     -1967px -167px),
  check-hover:           (17,    16,     -1967px -67px),
  arrow-left-sm:         (12,    13,     -2018px -69px,   -2018px -169px),
  tooltip:               (19,    19,     -2065px -66px,   -2065px -166px),
  plus:                  (10,    10,     -2220px -70px,   -2220px -170px),
  minus:                 (10,    2,      -2269px -74px,   -2269px -174px),
  plus-border:           (18,    18,     -2315px -66px,   -2315px -66px),
  plus-lg:               (16,    16,     -2417px -67px,   -2417px -167px),
  close-red:             (16,    16,     -2467px -67px,   -2467px -167px),
  download-v2:           (16,    15,     -2567px -67px,   -2567px -167px),
  create:                (33,    36,     -2608px -56px,   -2608px -156px),
  login-tablet-white:    (15,    12,     -2918px -270px),
  login-tablet-blue:     (15,    12,     -2968px -270px),
  arrow-right-sm:        (12,    13,     -2768px -69px,   -2768px -169px),
  menu:                  (16,    14,     -2818px -68px,   -2818px -168px),
  investments-history:   (16,    13,     -2868px -69px,   -2868px -169px),
  bookmark:              (16,    21,     -2915px -66px,   -2915px -164px),
  aside-magnifier:       (18,    18,     -2962px -166px,   -2962px -67px),
  aside-bookmark:        (18,    18,     -3051px -166px,   -3051px -67px),
  aside-archived:        (18,    18,     -3139px -166px,   -3139px -67px),
  aside-deals-room:      (20,    20,     -3094px -166px,   -3094px -67px),
  header-notification:   (22,    22,     -3269px -67px,   -3269px -162px),
  header-messages:       (22,    22,     -3225px -67px,   -3225px -162px),
  header-question:       (22,    22,     -3181px -67px,   -3181px -162px),

  // static
  quote:                 (49,    38,     -51px   -256px),
  select-value-icon:     (9,     9,      -170px  -271px),
  search:                (16,    16,     -216px  -268px),
  email:                 (16,    12,     -267px  -269px),
  magnifier:             (16,    16,     -216px  -268px),
  magnifier-2:           (18,    18,     -2962px -166px),
  password:              (16,    16,     -317px  -266px),
  mark-error:            (13,    13,     -418px  -269px),
  mark-success:          (15,    15,     -468px  -268px),
  label-error:           (15,    15,     -516px  -268px),
  checked-lg:            (16,    14,     -566px  -268px),
  checked-arr:           (13,    13,     -619px  -270px),
  upload:                (13,    16,     -668px  -267px),
  checked-md:            (11,    10,     -719px  -271px),
  select-arrow-white:    (16,    16,     -767px  -266px),
  delete-white:          (19,    19,     -865px  -266px),
  t-lines:               (23,    18,     -914px  -267px),
  bell:                  (9,     13,     -970px  -269px),
  draft:                 (11,    11,     -1019px -271px),
  warning-gray:          (12,    12,     -1068px -271px),
  eye:                   (18,    10,     -1116px -271px),
  loading:               (12,    12,     -1169px -271px),
  lock-white:            (13,    18,     -1218px -266px),
  dots:                  (16,    4,      -1266px -274px),
  unlock-white:          (17,    18,     -1316px -266px),
  email-2:               (18,    14,     -1366px -268px),
  phone:                 (16,    16,     -1416px -267px),
  marker:                (10,    18,     -1470px -266px),
  photo:                 (16,    14,     -1516px -268px),
  feather:               (26,    26,     -1565px -263px),
  arrow-to-top:          (21,    23,     -1614px -64px),
  filter-blue:           (26,    26,     -1714px -128px),
  filter-black:          (26,    26,     -1714px -263px),
  windows-cyan-sm:       (12,    12,     -1769px -269px),
  windows-orange-sm:     (12,    12,     -1819px -269px),
  windows-violet-sm:     (12,    12,     -1869px -269px),
  windows-darkviolet-sm: (12,    12,     -3018px -269px),
  lock-green-d-sm:       (10,    14,     -1920px -268px),
  lock-green-h-sm:       (10,    14,     -1970px -268px),
  lock-green-l-sm:       (10,    14,     -2020px -268px),
  windows-violet-md:     (16,    16,     -2067px -267px),
  windows-cyan-md:       (16,    16,     -2117px -267px),
  windows-orange-md:     (16,    16,     -2167px -267px),
  lock-green-d-md:       (12,    16,     -2219px -267px),
  lock-green-h-md:       (12,    16,     -2269px -267px),
  lock-green-l-md:       (12,    16,     -2319px -267px),
  filter-gray:           (26,    26,     -2166px -67px),
  mark-green:            (18,    16,     -2366px -268px),
  magnifier-white:       (18,    16,     -2416px -268px),
  edit:                  (22,    16,     -2463px -267px),
  closer-gray:           (20,    20,     -2466px -65px),
  arrow-down:            (8,     10,     -2521px -271px),
  paper-sheets:          (14,    18,     -2567px -267px),
  mark-success-gray:     (14,    12,     -2668px -270px),
  money-dollar:          (12,    14,     -2719px -269px),
  clock:                 (12,    12,     -2769px -270px),
  learnmore-scroll:      (24,    20,     -2813px -266px),
  close-blue:            (16,    16,     -2867px -267px),

  // second row

  denomination:          (19,    18,     -66px   -316px),
  purse:                 (15,    20,     -117px  -315px),
  frame-flat:            (20,    18,     -165px  -316px),
  frame:                 (20,    18,     -214px  -316px),
  linkedin-v2:           (14,    14,     -268px  -318px),
  firm:                  (14,    14,     -318px  -318px),
  phone-2:               (12,    19,     -369px  -315px),
  email-3:               (17,    13,     -416px  -318px),
  verified:              (14,    18,     -469px  -315px),
  verified-sm:           (10,    12,     -520px  -318px),
  browser-chrome:        (28,    28,     -611px  -312px),
  browser-firefox:       (28,    28,     -661px  -312px),
  browser-safari:        (28,    28,     -711px  -312px),
  browser-edge:          (25,    28,     -762px  -312px),
  calendar-light:        (16,    17,     -867px  -316px),
  calendar-dark:         (16,    17,     -917px  -316px),
);

$icons-lg: (
  // name:                (width, height, static-position)
  mobile-touch:           (116,   110,    -13px   -76px),
  loop:                   (102,   110,    -172px  -76px),
  global-connection:      (95,    136,    -329px  -81px),
  global:        url         (128,   128,    -461px  -58px),
  optimize:               (128,   128,    -611px  -54px),
  lock:                   (128,   128,    -762px  -54px),
  avatar-default:         (220,   195,    -1191px -90px),

  card:                   (145,   100,    -2px    -228px),
  communicate:            (158,   92,     -158px  -232px),
  deep-look:              (317,   228,    -317px  -228px),
  empty:                  (128,   128,    -459px  -236px),
  document-csv:           (43,    56,     -652px  -251px),
  document-doc:           (43,    56,     -802px  -251px),

  bell:                   (104,   96,     -28px   -377px),
  msg-blocked:            (128,   116,    -160px  -367px),
  new-msg:                (128,   116,    -311px  -367px),
  clock:                  (48,    50,     -499px  -401px),
  document-pdf:           (43,    56,     -652px  -401px),
  document-docx:          (43,    56,     -803px  -401px),
  avatar-group:           (293,   198,    -905px  -352px),

  empty-v2:               (128,   104,    -11px   -525px),
  box:                    (150,   128,    0       -660px),
  documents:              (269,   97,     -166px  -677px),
  dashboard:              (67,    65,     -641px  -692px),
  communications:         (67,    65,     -792px  -692px),

  partnership:            (67,    65,     -42px   -846px),
  interactions:           (67,    65,     -42px   -846px),
  leverage:               (67,    65,     -190px  -849px),
  certifications:         (67,    66,     -341px  -847px),
  share:                  (67,    65,     -493px  -847px),
  control:                (67,    65,     -642px  -847px),
  standarts:              (67,    65,     -792px  -847px),

  access:                 (67,    65,     -42px   -997px),
  browse:                 (67,    65,     -191px  -997px),
  indicate:               (67,    65,     -342px  -997px),
  obtain:                 (67,    65,     -491px  -997px),
  tools:                  (67,    65,     -642px  -1000px),
  centralized:            (67,    65,     -791px  -996px),

  connections:            (67,    65,     -42px   -1143px),
  updates:                (67,    65,     -191px  -1146px),
  connect:                (67,    65,     -343px  -1146px),
  cell-empty:             (128,   98,     -461px  -1124px),
  cell-bell:              (128,   98,     -610px  -1125px),
  cell-dots:              (128,   98,     -762px  -1125px),

  new-msg--desktop:       (64,    58,     -43px   -1297px),
  deal--desktop:          (64,    52,     -193px  -1322px),
  dashboard--desktop:     (64,    60,     -345px  -1296px),
  empty--desktop:         (64,    46,     -493px  -1305px),
  bell--desktop:          (46,    53,     -652px  -1301px),
  msg-blocked--desktop:   (64,    58,     -792px  -1298px),

  cell-empty--desktop:    (64,   49,      -43px   -1449px),
  cell-bell--desktop:     (64,   49,      -192px  -1449px),
  cell-dots--desktop:     (64,   49,      -344px  -1449px),
  notification:           (64,   64,      -491px  -1441px),

  document-docx-sm:       (27,   36,      -660px  -1458px),
  document-doc-sm:        (27,   36,      -737px  -1458px),
  document-pdf-sm:        (27,   36,      -811px  -1458px),

  not-support:            (64,   60,      -43px   -1595px),
  document-xls:           (42,    56,     -204px  -1599px),
  document-xlsx:          (42,    56,     -354px  -1599px),
  document-xls-sm:        (27,    36,     -511px  -1609px),
  document-xlsx-sm:       (27,    36,     -661px  -1609px),
  investments-empty:      (120,   126,    -766px  -1563px),
  document-zip:           (43,    56,     -943px  -1445px),
  document-zip-sm:        (27,    36,     -951px  -1607px),

  completed:              (64,    64,     -943px  -693px),
  security:              (48,    48,     -951px  -856px),
);
