@import '../../../styles/base';

.avatar-image {
  border-radius: 2px;
  position: relative;

  .icon-lg {
    bottom: -20px;
    left: -10px;
    position: absolute;
  }
}
