@import '../FormControl/FormControl';

$text-area-padding: 12px;

.text-area {
  @include form-control(textarea);
  flex: 1;
  min-height: 84px;

  textarea {
    max-height: 100%;
    padding-bottom: $text-area-padding;
    padding-top: $text-area-padding;
    resize: none;
    z-index: 0;

    &:-webkit-autofill,
    &:-webkit-autofill:focus {
      -webkit-box-shadow: 0 0 0 100px color(pbg) inset;
      -webkit-text-fill-color: color(gray-d);
    }

    @include breakpoint(tablet) {
      max-height: 120px;
    }

    @include breakpoint(mobile) {
      max-height: 100px;
    }
  }

  // states
  &#{&}--disabled {
    @extend %input-disabled;
  }
}
