@import '../../../styles/base';

.preloader-view {
  align-items: center;
  background-color: color(blue-dark2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  opacity: 1;
  overflow: hidden;
  width: 100vw;
  z-index: 1000;

  &__line {
    background-color: rgba(color(white), 0.05);
    height: 4px;
    margin-top: 40px;
    max-width: 300px;
    position: relative;
    overflow: hidden;
    width: 400px;

    &:before {
      animation: loading 2s linear infinite;
      background-color: color(white);
      content: '';
      display: block;
      height: 4px;
      left: -200px;
      position: absolute;
      width: 200px;
    }
  }

  &--hide {
    animation: hidePreloader 0.35s linear;
    opacity: 0;
    height: 0;
    transition: height 0s ease 0.36s;
  }
}

@keyframes hidePreloader {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes loading {
  from {
    left: -200px;
    width: 30%;
  }
  40% {
    width: 30%;
  }
  70% {
    width: 70%;
  }
  80% {
    left: 50%;
  }
  95% {
    left: 120%;
  }
  to {
    left: 100%;
  }
}
