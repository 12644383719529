@import '../../../styles/base';

.dropzone {
  &__container {
    position: relative;
  }

  &__extensions {
    color: color(gray-d);
    font-size: 12px;
    margin-top: 5px;
    position: absolute;
  }

  &__helper {
    align-items: center;
    color: color(red-error);
    display: flex;
    justify-content: center;
    visibility: visible;
    position: absolute;
    bottom: -1px;
    transform: translateY(100%);

    &:not(:empty):before {
      @include icon(mark-error, url(../../../images/icons/icons_small.png));
      flex-shrink: 0;
      margin-right: 5px;
      margin-top: -1px;
    }
  }
}
