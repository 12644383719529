@import '../../../../styles/base';

.activity-feeds {
  &__header .activity-feeds__row__l-col {
    padding-left: 290px;
  }

  &__filter {
    color: color(blue-g);
    cursor: pointer;
    display: flex;
    font-size: 12px;
    font-weight: 700;
    margin-left: 27px;
    padding-top: 3px;
    text-transform: uppercase;

    @include breakpoint(mobile) {
      margin: 0 10px;
      padding-top: 0;
    }

    span {
      margin: 0 22px 0 7px;

      @include breakpoint(mobile) {
        display: none;
      }
    }
  }

  &--small & {
    &__header .activity-feeds__row__l-col {
      padding-left: 106px;

      @include breakpoint(mobile) {
        align-items: center;
        display: flex;
        flex: 0;
        padding: 0;
      }
    }
  }

  &__header {
    background: color(pbg);
    border-bottom: 1px solid color(gray-vl);
    box-sizing: border-box;

    &__first-button {
      color: color(gray);
      margin-right: 10px;

      @include breakpoint(mobile) {
        margin-right: 5px;
      }
    }

    .activity-feeds__row {
      padding: 11px 0;

      &__l-col {
        align-items: center;
      }

      &__r-col {
        flex: 0 0 280px;
        padding-top: 2px;

        @include breakpoint(mobile) {
          display: flex;
          flex: 1;
          justify-content: flex-end;
          padding-top: 0;
        }

        .btn {
          @include breakpoint(mobile) {
            border: none;
            min-height: 28px;

            button {
              font-size: 10px;
            }
          }
        }
      }
    }

    .tag {
      height: 30px;
      left: 8px;
      position: relative;

      @include breakpoint(mobile) {
        font-size: 10px;
        height: 18px;
        position: static;
      }

      .icon {
        cursor: pointer;
      }
    }

    &-container {
      padding-right: 50px;
    }
  }
}
