.active-filters {
  display: flex;
  flex: 1;
  margin-left: 30px;

  span {
    align-items: flex-start;
    display: flex;
    flex: 0 0 85px;
    margin-top: 6px;
  }

  &__tags {
    display: flex;
    flex-wrap: wrap;
    margin-left: 10px;

    .tag {
      margin: 0 10px 10px 0;
    }
  }
}
