@import '../../../../styles/base';

.am-investor-card {
  display: flex;
  justify-content: space-between;
  padding: 30px 25px 15px;

  @include breakpoint(desktop, zero) {
    padding-right: 25px;
  }

  @include breakpoint(mobile) {
    flex-direction: column;
    padding: 20px 20px 40px;
  }

  &__main {
    display: flex;
    flex-direction: column;
    min-width: 642px;

    @include breakpoint(desktop-mid-large) {
      min-width: 690px;
    }

    @include breakpoint(desktop, zero) {
      min-width: 430px;
    }

    @include breakpoint(mobile) {
      border-bottom: 1px solid color(gray-vl);
      min-width: initial;

      .tag-group {
        margin: 25px 0 0;
      }
    }
  }

  &__header {
    display: flex;
  }

  &__avatar {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-right: 30px;

    .btn {
      margin-top: 25px;
    }

    &__profile {
      color: color(green-d);
      font-size: 11px;
      font-weight: 700;
      margin-top: 5px;
      text-align: center;
      width: 70px;
    }
  }

  &__info {
    display: flex;
    justify-content: space-between;
    margin: 28px 0 26px;

    @include breakpoint(desktop, zero) {
      flex-direction: column;
    }

    &__row,
    &__col {
      .tag {
        margin: 0 4px 4px 0;

        @include breakpoint(desktop, zero) {
          margin-bottom: 0;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }

    &__row {
      display: flex;
      flex-wrap: wrap;

      &__item {
        font-size: 12px;
        flex: 1;
        margin-bottom: 13px;
      }

      &:first-child {
        @include breakpoint(mobile) {
          flex-direction: column;
        }
      }

      &:first-child & {
        &__item {
          > div:last-child {
            color: color(blue-g);
            font-family: acumin-pro;
            font-size: 20px;
            font-weight: bold;
          }

          &--green {
            div:last-child {
              color: color(cyan-p);
            }
          }
        }
      }
    }

    .accordion {
      box-sizing: border-box;
      flex: 1 0 50%;

      &:nth-child(odd) {
        padding-right: 20px;
      }

      &__title {
        .icon {
          margin-right: 13px;
        }
      }

      &--open {
        flex-basis: 100%;

        .accordion__content {
          padding-left: 32px;
        }
      }
    }

    &__col {
      display: flex;
      flex: 1;
      flex-direction: column;
      margin-right: 40px;
      max-width: 320px;
      min-width: 225px;

      @include breakpoint(desktop-mid-large) {
        margin-right: 30px;
      }

      @include breakpoint(desktop, zero) {
        margin-right: 25px;
        min-width: initial;
        max-width: 192px;
      }

      &__item {
        padding-left: 33px;
        margin-bottom: 17px;

        > div:first-child {
          margin-bottom: 4px;
        }

        > div:last-child {
          align-items: center;
          color: color(blue-g);
          position: relative;

          .icon {
            content: '';
            left: -33px;
            position: absolute;
            top: 3px;
          }
        }

        &:first-child {
          height: 58px;
          margin-bottom: 37px;

          div:last-child {
            font-size: 30px;
            font-family: acumin-pro;
            font-weight: bold;
            white-space: nowrap;

            @include breakpoint(desktop-large) {
              font-size: 24px;
            }

            .icon {
              top: 16px;

              @include breakpoint(desktop-large) {
                top: 12px;
              }
            }
          }
        }

        &--green {
          div:last-child {
            color: color(cyan-p);
          }
        }
      }
    }
  }

  &__contacts {
    align-self: flex-start;
    border-left: 1px solid color(gray-vl);
    box-sizing: border-box;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    max-width: 605px;
    min-width: 220px;
    padding: 34px 0 27px 25px;

    @include breakpoint(desktop, zero) {
      min-width: 200px;
      padding: 34px 0 0 18px;
    }

    @include breakpoint(mobile) {
      border-left: 0;
      padding: 34px 0 0 0;
    }

    &__content {
      align-items: center;
      display: flex;
      margin-bottom: 24px;

      .icon-lg {
        flex-shrink: 0;
        margin-right: 24px;
      }
    }

    &__name {
      font-weight: bold;
    }

    &__title {
      color: color(headings);
      display: flex;
      flex-wrap: wrap;
      font-size: 18px;
      margin-bottom: 35px;

      @include breakpoint(desktop, zero) {
        font-size: 14px;
        margin-bottom: 21px;
      }
    }

    &__controls {
      display: flex;

      .btn {
        margin-right: 5px;

        &.invite-button {
          width: 160px;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }

    &__items {
      display: flex;
      flex-wrap: wrap;

      &__item {
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;
        font-size: 14px;
        margin-bottom: 27px;
        margin-right: 20px;
        padding-left: 32px;
        max-width: 50%;
        white-space: nowrap;

        @include breakpoint(desktop) {
          flex: 1 0 50%;
          margin: 0 0 11px;
        }

        @include breakpoint(desktop, zero) {
          font-size: 12px;
        }

        &:last-child {
          margin-right: 0;
        }

        > div:first-child {
          align-items: center;
          color: color(texts);
          display: flex;
          margin-bottom: 7px;
          position: relative;

          @include breakpoint(desktop, zero) {
            margin-bottom: 0;
          }

          .icon {
            left: -32px;
            position: absolute;
          }
        }

        > div:last-child {
          align-items: center;
          color: color(headings);
          display: flex;

          a {
            margin-right: 10px;
          }
        }
      }
    }
  }

  &__main-info {
    display: flex;
    flex: 1;
    flex-direction: column;

    &__name {
      color: color(blue-m);
      display: flex;
      font-size: 24px;
      margin-top: -5px;

      @include breakpoint(desktop-large, infinity) {
        align-items: center;
        display: flex;
      }

      @include breakpoint(desktop, zero) {
        font-size: 18px;
      }
    }

    &__pipeline {
      align-items: center;
      color: color(cyan-p);
      display: flex;
      font-size: 11px;
      margin-left: 20px;
      text-transform: uppercase;

      .icon {
        margin-right: 8px;
      }
    }

    &__type {
      color: color(gray-d);
      font-size: 12px;
      margin-top: -5px;
    }

    .tag-group {
      margin-left: -10px;

      @include breakpoint(desktop, zero) {
        margin-left: -5px;
        margin-top: 15px;

        .tag {
          font-size: 12px;
          text-transform: capitalize;
        }
      }
    }
  }
}
