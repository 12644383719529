@import '../../../styles/base';

.notes {
  &__empty {
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    min-height: 311px;
    position: relative;

    &__container {
      align-items: center;
      display: flex;
      flex-direction: column;
      margin-top: 10px;

      & > span {
        padding: 7px 0;
      }
    }

    .notes__header {
      border-bottom: none;
    }

    span {
      color: color(gray-d);
      font-size: 17px;

      @include breakpoint(desktop, zero) {
        font-size: 15px;
      }
    }

    .icon-lg {
      display: flex;
      margin-bottom: 20px;
      position: relative;
    }
  }
}
