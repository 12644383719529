@import '../../../../../styles/base';

.user-nav-modal {
  &__body {
    @extend %dm-scroll;

    @include breakpoint(mobile) {
      display: block;
    }
  }

  &__row {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    margin: 0 -23px;
    margin-bottom: 20px;

    @include breakpoint(tablet) {
      width: 450px;
    }

    &:nth-child(n + 2) {
      border-top: 1px solid color(gray-vl);
      padding-top: 30px;
    }

    &--fund {
      .link {
        &--active {
          border-left: 3px solid color(blue-m);
          padding-left: 15px;
        }
      }
    }
  }

  &__user {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;

    &__name {
      color: color(headings);
      font-size: 18px;
      margin: 15px 0 20px 0;
      max-width: 200px;
      text-align: center;
    }
  }

  &__control-group {
    display: flex;
    margin-bottom: 20px;

    .link {
      color: color(blue-g);
      cursor: pointer;
      font-size: 12px;
      padding: 0 15px;
      text-align: right;
      text-transform: uppercase;
      width: 100px;

      &:first-child {
        border-right: 1px solid color(gray-vl);
      }
    }
  }

  &__list {
    &__items {
      .link {
        display: block;
        font-size: 16px;
        line-height: 2em;
        margin: 6px 0 6px -15px;
        padding-left: 18px;
      }
    }
  }
}
