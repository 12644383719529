%horizontal-center {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

%vertical-center {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

%center-center {
  @extend %horizontal-center;
  @extend %vertical-center;
  transform: translate(-50%, -50%);
}

%with-hover {

  &:hover {
    color: color(blue-m);
  }
}

%dm-scroll {

  &::-webkit-scrollbar {
    background-color: transparent;
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: color(blue-light3);
    border-radius: 4px;

    &:hover {
      background-color: color(blue-light4);
    }

    &:active {
      background-color: color(blue-g);
    }
  }
}

%input-disabled {
  background-color: color(sbg);
  cursor: not-allowed;
}
