@import '../../../../styles/base';

.activity-feeds {
  &__empty-box {
    @extend %center-center;

    .icon-lg {
      @extend %horizontal-center;
      display: flex;
      position: relative;
      margin-bottom: 25px;
    }
  }
}
