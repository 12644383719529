@import '../../../../styles/base';

.activity-feeds {
  &__sidebar {
    display: flex;
    color: color(texts);
    flex: 0 0 270px;
    flex-direction: column;
    font-size: 14px;
    padding-top: 10px;

    @include breakpoint(desktop-large, infinity) {
      align-items: center;
    }

    @include breakpoint(mobile) {
      display: none;
    }
  }
}
