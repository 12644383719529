@import '../../../../../../styles/base';

.no-longer-modal {
  .form {
    flex-direction: column;

    p {
      color: color(texts);
      text-align: center;
    }

    @include breakpoint(mobile) {
      .modal__controls--mobile {
        .btn {
          font-size: 14px;
        }
      }
    }
  }

  &__fields {
    margin: 30px auto;
    width: 385px;

    @include breakpoint(mobile) {
      max-width: 314px;
    }
  }
}
