@import '../../../styles/base';
@import './DropdownVariables';

.dropdown-triangle {
  position: absolute;

  &__square {
    height: 2 * map-get($dropdown, triangle-size);
    overflow: hidden;
    position: absolute;
    width: 2 * map-get($dropdown, triangle-size);

    &:after {
      background: #fff;
      box-shadow: -1px -1px #{3.333 * map-get($dropdown, shadow-size)} -2px rgba(0, 0, 0, 0.5);
      content: '';
      height: map-get($dropdown, triangle-size);
      left: map-get($dropdown, triangle-centering-size);
      position: absolute;
      top: map-get($dropdown, triangle-direction-size);
      transform: rotate(45deg);
      width: map-get($dropdown, triangle-size);
    }
  }
}
