@import '../../../../styles/base';

.activity-feeds {
  &__row {
    background: color(pbg);
    display: flex;
    margin-bottom: 10px;
    padding: 14px 20px;

    @include breakpoint(mobile) {
      margin-bottom: 4px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &__l-col {
      display: flex;
      position: relative;
      width: 100%;

      @include breakpoint(mobile) {
        align-items: center;
      }
    }

    &__r-col {
      flex: 0 0 140px;
      text-align: right;
    }

    p,
    &__msg {
      color: color(texts);
    }

    &--select {
      background: color(blue-m);

      .activity-feeds__row__msg,
      p {
        color: color(white);
      }
    }

    p {
      font-size: 14px;
      margin: 0;
    }

    &__msg-content {
      flex: 1;
      padding: 1px 0 0 40px;
      width: 0;
      word-wrap: break-word;

      @include breakpoint(mobile) {
        padding: 0;
        padding-left: 30px;
      }

      p {
        word-wrap: break-word;
      }
    }

    &__msg {
      font-size: 12px;
      position: relative;
      top: 1px;

      @include breakpoint(mobile) {
        font-size: 10px;
      }
    }

    mark {
      @extend %vertical-center;
      background: color(red-s);
      border-radius: 50%;
      display: block;
      font-size: 0;
      height: 8px;
      left: 34px;
      width: 8px;
    }

    .checkable {
      align-items: center;
      display: flex;
    }
  }
}
