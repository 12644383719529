@import '../../../styles/base';

.dropdown {
  position: relative;

  &--opened {
    > .dropdown-content {
      display: flex;
    }
  }
}
