@import '../../../styles/base';

$active-bg-color: color(blue-m);
$active-color: color(white);

.aside-link {
  align-items: center;
  border-radius: 4px;
  display: flex;
  height: 37px;
  position: relative;

  &,
  &:visited {
    color: color(blue-aside-link);
  }

  &:hover,
  &:active,
  &--active,
  &--active:visited {
    color: $active-color;
  }

  &--active {
    background: $active-bg-color;
  }

  &--active & {
    &__counter {
      background: color(red);
      border-radius: 4px;
      color: color(white);
    }
  }

  &__text {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1 1 0%;
    overflow: hidden;
    padding: 0 15px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__counter {
    align-items: center;
    background: color(red);
    border-radius: 4px;
    color: $active-color;
    display: inline-flex;
    font-size: 11px;
    justify-content: center;
    height: 20px;
    min-width: 20px;
    right: $aside-margin;
  }
}
