.error-box {
  padding: 1rem;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  h1,
  h2 {
    margin: 20px 0;
  }
  img {
    margin: 20px -80px 0 20px;
  }
}
