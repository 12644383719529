.gmap {
  height: 345px;
  min-width: 100%;
}

.gmap_container {
  height: 100%;
}

.gmap_loader_container {
  height: 100%;
}
