@import '../../../../../../styles/base';

.fund-show-statictics {
  display: flex;
  margin-bottom: 21px;

  @include breakpoint(mobile) {
    flex-direction: column;
  }

  &__performance {
    display: flex;
    flex: 0 0 400px;
    flex-direction: column;
    font-size: 14px;
    margin-right: 30px;

    @include breakpoint(tablet) {
      flex: 1;
    }

    @include breakpoint(mobile) {
      flex: auto;
      margin-bottom: 35px;
      margin-right: 0;
    }

    &__head {
      color: color(headings);
      display: flex;
      justify-content: space-between;
      margin-bottom: 6px;
      padding: 0 27px 0 12px;
    }

    &__row {
      align-items: center;
      background-color: color(sbg);
      border-radius: 4px;
      color: color(texts);
      display: flex;
      min-height: 26px;
      margin-bottom: 4px;
      padding: 10px;
      padding-right: 0;

      &__label {
        display: flex;
        flex: 1;
      }

      &__value {
        display: flex;
        flex: 0 0 140px;
        justify-content: center;
      }
    }
  }

  &__chart {
    display: flex;
    flex: 1;
    flex-direction: column;

    @include breakpoint(tablet) {
      display: block;
      width: 50%;
    }

    @include breakpoint(mobile) {
      margin-left: -10px;
      width: 100%;
    }

    &__label {
      color: color(headings);
      padding-left: 49px;
      //position: relative;
      //top: 39px;

      @include breakpoint(mobile) {
        padding-left: 10px;
      }
    }

    #green-chart {
      position: relative;
      top: -18px;
    }
  }
}
