@import '../../../../styles/base';

.filters-panel {
  .panel__content {
    padding: 34px 3.47% 22px;

    @include breakpoint(tablet) {
      padding: 34px 20px 20px;
    }

    @include breakpoint(mobile) {
      .form {
        align-items: center;
        display: flex;
        flex-direction: column;
      }
    }
  }

  &__fields {
    display: flex;
    justify-content: space-between;
  }

  &__controls {
    display: flex;
    justify-content: flex-end;
    position: relative;

    @include breakpoint(mobile) {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      position: relative;
      width: 340px;
    }

    &__center {
      @extend %horizontal-center;

      .btn {
        font-size: 12px;
      }
    }

    &__reset {
      margin-left: 10px;
    }
  }
}
