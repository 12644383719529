@import '../variables';

.button,
.button_link {
  border: 0;
  background-color: $blue;
  border-radius: 2px;
  color: $white;
  cursor: pointer;
  font-weight: 700;
  outline: none;
  padding: 0 20px;
  text-transform: uppercase;
  text-decoration: none;

  @include breakpoint(tablet_l, zero) {
    padding: 0 15px;
  }

  @include breakpoint(mobile) {
    padding: 0 10px;
  }

  &:hover {
    color: $white;
    background-color: $blue-active;
    text-decoration: none;
  }

  &:active {
    background-color: $blue;
  }
}

.button {
  display: inline-block;

  &:disabled,
  &:disabled:hover {
    background-color: $grey;
    color: $white;
    cursor: not-allowed;
  }
}

.button_link {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

/* ----- TYPES / STATES ----- */

.accept {
  background-color: $green;

  &:hover {
    background-color: $green-active;
  }
}

.secondary {
  background-color: transparent;
  border: 1px solid $grey;
  color: $blue;

  &:hover {
    background-color: $blue;
    border-color: $blue;
    color: $white;
  }

  &:active {
    background-color: $blue-active;
    border-color: $blue-active;
    color: rgba($white, 0.8);
  }

  &:disabled,
  &:disabled:hover {
    background-color: transparent;
    border-color: $grey;
    color: $grey;
  }
}

.flat {
  background-color: transparent;
  color: $white;
}

/* ----- SIZES ----- */

.s {
  font-size: 14px;
  line-height: 17px;
  height: 38px;
}

.m {
  font-size: 15px;
  line-height: 18px;
  height: 44px;
}

.l {
  font-size: 17px;
  line-height: 20px;
  height: 56px;
}
