@import '../variables';

.counter {
  background-color: $red;
  position: absolute;
  right: 0;
  top: -6px;
  transform: translateX(100%) scale(1);
  transition: 0.2s;

  &.numeric {
    align-items: center;
    border-radius: 8px;
    color: $white;
    display: flex;
    justify-content: center;
    font-size: 8px;
    font-weight: bold;
    height: 16px;
    min-width: 16px;
    padding: 0 5px;
  }

  &.event {
    border-radius: 50%;
    height: 6px;
    top: -2px;
    width: 6px;
  }

  &.hide {
    transform: translateX(100%) scale(0);
    transition: 0.2s;
  }
}
