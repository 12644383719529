@import '../../../styles/base';

.table-cell {
  align-items: center;
  border-right: 1px solid color(blue-l);
  box-sizing: border-box;
  color: color(texts);
  display: flex;
  flex: 1;
  overflow: hidden;
  padding: 0 20px;
  position: relative;

  @include breakpoint(tablet, zero) {
    padding: 0 10px;
  }

  &:last-child {
    border-right: 0;
  }

  &__wrap {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 0;
  }

  &__hidden {
    display: flex;
    visibility: hidden;
  }

  &--hidden > * {
    visibility: hidden;
  }

  &--hide {
    display: none;

    &-tablet {
      @include breakpoint(tablet) {
        display: none;
      }
    }

    &-mobile {
      @include breakpoint(mobile) {
        display: none;
      }
    }
  }

  &--without-padding {
    padding: 0;
  }

  &--chackable {
    align-items: center;
    flex: 0;
    justify-content: center;
    padding: 0;

    @include breakpoint(desktop, zero) {
      flex-basis: 60px;
    }

    @include breakpoint(desktop-large, infinity) {
      flex-basis: 80px;
    }
  }

  &--sorting {
    .icon {
      &--select-arrow {
        display: flex;
        margin-left: 5px;
      }
    }
  }

  &--user & {
    &__name {
      border-bottom: 1px dashed color(headings);
      color: color(headings);
      margin-left: 14px;
    }

    &__card {
      cursor: pointer;
      margin-left: 10px;
    }
  }

  &--controls {
    justify-content: space-between;
    overflow: visible;

    .tag {
      cursor: pointer;
    }
  }
}
