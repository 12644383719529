@import '../../../styles/base';

#toast-container {
  top: 93px;

  &.toast-top-center {
    > div {
      opacity: 1;
      display: flex;
      align-items: center;

      @include breakpoint(tablet, infinity) {
        width: 480px;
      }
    }
  }

  > div {
    background-color: color(pbg);
    min-height: 77px;
    padding: 22px 20px 22px 72px;
    position: relative;
    width: 480px;

    .toast-title {
      display: none;
    }

    .toast-message {
      color: color(headings);
      line-height: 22px;
    }

    &:before {
      background-image: url('../../../images/icons/icons_small.png') !important;
      content: '';
      height: 32px;
      left: 20px;
      position: absolute;
      top: 25px;
      width: 32px;
    }

    &.toast-info {
      background-image: none !important;

      &:before {
        background-position: -1659px -259px;
      }
    }

    &.toast-error {
      background-image: none !important;

      &:before {
        background-position: -1609px -259px;
      }
    }

    &.toast-success {
      background-image: none !important;

      &:before {
        background-position: -109px -259px;
      }
    }

    &.toast-warning {
      background-image: none !important;
    }
  }
}
