@import '../../../../styles/base';

.user-card {
  border: 1px solid color(gray-l);
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  height: 250px;
  padding: 19px;
  width: 380px;

  @include breakpoint(tablet, zero) {
    height: 230px;
    padding: 10px;
    width: 370px;
  }

  @include breakpoint(mobile) {
    width: 320px;
  }

  &__info {
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: hidden;
  }

  &__name {
    align-items: center;
    color: color(headings);
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    padding-left: 16px;

    .icon {
      display: none;
    }
  }

  &__details {
    display: flex;
    flex-direction: column;

    .form-group {
      height: 27px;
      justify-content: flex-start;
      margin-bottom: 0;

      .form-control-static {
        color: color(headings);
        flex: 1;
        display: initial;
        height: initial;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      label {
        color: color(texts);
        font-size: 14px;
        width: 57px;
      }
    }

    &__role.form-group {
      align-items: flex-start;
      height: 51px;

      .form-control-static {
        align-items: flex-start;
        flex-direction: column;
      }
    }

    &__change-owner {
      color: color(blue-g);
      cursor: pointer;
    }

    &__linkedin.form-group {
      .form-control-static {
        color: color(blue-g);

        .icon {
          margin-left: 15px;
        }
      }
    }
  }

  &:not(&--pending):hover {
    border-color: color(blue-m);
  }

  &:hover & {
    &__name {
      color: color(blue-m);

      .icon {
        cursor: pointer;
        display: block;
      }
    }
  }

  &--without-border {
    border: 0;
  }

  &--pending {
    border-style: dashed;
  }

  &--pending & {
    &__pending {
      align-items: center;
      color: color(gray-d);
      display: flex;
      font-size: 15px;
      flex: 1;
      flex-direction: column;
      justify-content: flex-end;
      padding: 0 74px 18px 0;

      .icon-lg {
        margin-bottom: 10px;
      }
    }
  }
}
