@import '../variables';

.ui-kit-input {
  position: relative;
  display: inline-block;
  width: 100%;

  input {
    margin: 0 0 20px 0;
    padding: 10px 40px 10px 10px;
    border: 1px solid $grey;
    border-radius: 2px;
    background: $white;
    font-family: museo-sans-rounded, sans-serif;
    font-weight: 500;
    font-size: 15px;
    height: 44px;
    width: 100%;
    line-height: 22px;
    color: $headline;
    vertical-align: middle;
    overflow: visible;
    appearance: none;

    &:focus {
      outline: 0;
      border-color: $caption;

      + .icon svg {
        fill: $caption;
      }
    }

    &:disabled {
      background-color: $bg;
      color: $grey;
      border-color: $line;
      cursor: not-allowed;

      + .icon svg {
        fill: $line;
      }
    }
  }

  .input-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    line-height: 0;
    svg {
      fill: $grey;
    }
  }

  .message {
    position: absolute;
    font-size: 12px;
    line-height: 16px;
    bottom: 4px;
    left: 0;
    white-space: nowrap;
  }
}

.danger {
  input {
    border-color: $red;
  }

  .message {
    color: $red-active;
  }

  .input-icon svg {
    fill: $red;
  }
}

.success {
  input {
    border-color: $green;
  }

  .message {
    color: $green-active;
  }

  .input-icon svg {
    fill: $green;
  }
}

.inpuui-kit-inputt {
  input::-moz-placeholder {
    opacity: 1;
  }
  input:-ms-input-placeholder {
    color: $caption;
  }
  input::-moz-placeholder {
    color: $caption;
  }
  input::-webkit-input-placeholder {
    color: $caption;
  }
  input:disabled:-ms-input-placeholder {
    color: $grey;
  }
  input:disabled::-moz-placeholder {
    color: $grey;
  }
  input:disabled::-webkit-input-placeholder {
    color: $grey;
  }
}
