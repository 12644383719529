@import '../../../styles/base';

.dm-logo {
  align-items: center;
  background: inherit;
  box-sizing: border-box;
  display: inline-flex;
  opacity: 0.9;

  &:hover {
    cursor: pointer;
    opacity: 1;
  }

  &--with-aside {
    padding-left: 2 * $aside-margin;
    width: $aside-width;

    @include breakpoint(desktop, infinity) {
      background: color(blue-m);
    }

    @include breakpoint(mobile) {
      padding-left: 20px;
    }
  }
}
