@import '../../../styles/base';

.progress {
  display: flex;
  height: 3px;
  left: 0;
  position: absolute;
  right: 0;
  top: -2px;

  &__strip {
    display: flex;
    position: relative;
    transition: all 500ms;
  }

  &__title {
    font-size: 14px;

    &__number {
      color: color(black);
      font-size: 32px;

      @include breakpoint(tablet, zero) {
        font-size: 24px;
      }
    }

    &__text {
      @include breakpoint(tablet, zero) {
        display: none;
      }
    }
  }

  // colors
  &--green {
    .progress__title {
      color: color(gray-d);
    }

    .progress__strip {
      background: color(green);
      border-radius: 1px;
    }
  }

  &--blue {
    background: color(blue-lg);

    .progress__title {
      color: color(blue-m);
    }

    .progress__strip {
      background: color(blue-m);
    }
  }

  // title positions
  &--top {
    .progress__title {
      position: absolute;
      right: 30px;
      top: -60px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      @include breakpoint(tablet, zero) {
        top: -30px;
      }

      @include breakpoint(mobile) {
        right: 0;
      }
    }
  }

  &--bottom {
    .progress__title {
      position: absolute;
      right: 4px;
      top: 12px;
    }
  }

  &--right {
    justify-content: space-between;

    .progress__title {
      position: relative;
      right: -10px;
      top: -10px;
      width: 0;
    }
  }
}
