@import '../ui-kit/variables';

.subscribed_success {
  min-height: 64px;
  color: $caption;
}

.subscribe-form {
  display: inline-flex;
  flex-wrap: wrap;
  @include breakpoint(mobile) {
    margin-bottom: 20px;
  }
  .field {
    margin-right: 20px;
    width: 220px;
    @include breakpoint(mobile) {
      margin-right: 0;
      width: 100%;
    }
  }
}
