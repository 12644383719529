@import '../variables';

.hint {
  position: relative;
  display: inline-block;
  display: inline-flex;
  align-items: center;

  .message {
    display: none;
    position: absolute;
    background: $white;
    border: 1px solid $line;
    border-radius: 2px;
    padding: 10px;
    z-index: 100;
    min-width: 260px;
    box-shadow: $shadow2;

    .triangle {
      position: absolute;
      text-align: center;
      overflow: hidden;

      > span {
        width: 12px;
        height: 12px;
        background: $white;
        border: 1px solid $line;
        transform: rotate(45deg);
        position: absolute;
      }
    }

    &.top-left,
    &.top-center,
    &.top-right {
      top: -12px;
      transform: translate(0, -100%);

      .triangle {
        bottom: -10px;
        width: 20px;
        height: 10px;

        > span {
          left: 4px;
          bottom: 4px;
        }
      }
    }

    &.top-left .triangle,
    &.bottom-left .triangle {
      left: 20px;
    }

    &.top-center {
      left: 50%;
      transform: translate(-50%, -100%);

      .triangle {
        left: 50%;
        margin-left: -10px;
      }
    }

    &.top-right,
    &.bottom-right {
      right: -20px;

      .triangle {
        right: 20px;
      }
    }

    &.bottom-left,
    &.bottom-center,
    &.bottom-right {
      bottom: -12px;
      transform: translate(0, 100%);

      .triangle {
        top: -10px;
        width: 20px;
        height: 10px;

        > span {
          left: 4px;
          top: 4px;
        }
      }
    }

    &.bottom-center {
      left: 50%;
      transform: translate(-50%, 100%);

      .triangle {
        left: 50%;
        margin-left: -10px;
      }
    }

    &.right-top,
    &.right-center,
    &.right-bottom {
      right: -12px;
      transform: translate(100%, 0);

      .triangle {
        width: 10px;
        height: 20px;
        left: -10px;

        > span {
          left: 4px;
          bottom: 4px;
        }
      }
    }

    &.right-top {
      top: 0;

      .triangle {
        top: 10px;
      }
    }

    &.right-center {
      top: 50%;
      transform: translate(100%, -50%);

      .triangle {
        top: 50%;
        margin-top: -10px;
      }
    }

    &.right-bottom {
      bottom: 0;

      .triangle {
        bottom: 10px;
      }
    }

    &.left-top,
    &.left-center,
    &.left-bottom {
      left: -12px;
      transform: translate(-100%, 0);

      .triangle {
        width: 10px;
        height: 20px;
        right: -10px;

        > span {
          right: 4px;
          bottom: 4px;
        }
      }
    }

    &.left-top {
      top: 0;

      .triangle {
        top: 10px;
      }
    }

    &.left-center {
      top: 50%;
      transform: translate(-100%, -50%);

      .triangle {
        top: 50%;
        margin-top: -10px;
      }
    }

    &.left-bottom {
      bottom: 0;

      .triangle {
        bottom: 10px;
      }
    }
  }

  &:hover {
    .message {
      display: block;
    }
  }
}
