@import '../../../../../../styles/base';

.cooling-off-period-modal {
  .icon--tooltip {
    position: relative;
    top: 5px;
  }

  &__days {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 35px;

    &__number {
      align-items: center;
      background-color: color(blue-g);
      border-radius: 4px;
      color: color(white);
      display: flex;
      justify-content: center;
      font-size: 48px;
      font-weight: 700;
      height: 59px;
      width: 39px;

      &:last-of-type {
        margin: 0 15px 0 2px;
      }
    }
  }

  &__footer {
    height: 130px;
    flex-direction: column;

    @include breakpoint(mobile) {
      height: auto;
      text-align: center;
    }

    p {
      margin: 0 0 20px;
    }
  }
}
