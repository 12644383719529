@import '../../../styles/base';

.tab-link {
  cursor: pointer;
  font-weight: 600;
  flex-shrink: 0;
  padding: 12px 18px;
  text-transform: uppercase;

  @include breakpoint(tablet) {
    font-size: 12px;
  }

  &:visited {
    color: color(blue-g);
  }

  &--active,
  &--active:visited {
    background-color: color(pbg);
    border: solid color(gray-vl);
    border-radius: 4px 4px 0 0;
    border-width: 1px 1px 0 1px;
    color: color(blue-m);
    margin-bottom: -1px;
    padding: 11px 17px 13px;
  }
}
