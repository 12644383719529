@import '../../../styles/base';

.base-template {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-width: map-get($layout-width, desktop);
  position: relative;

  @include breakpoint(tablet) {
    min-width: map-get($layout-width, tablet);
  }

  @include breakpoint(mobile) {
    min-width: map-get($layout-width, mobile);
  }

  main {
    display: flex;
    flex: 1;
    flex-direction: column;
    min-height: 60vh;
  }

  .container {
    display: flex;
    flex-direction: column;
    position: relative;
  }
}
