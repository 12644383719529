@import 'variables';

.widget {
  background-color: rgba(red, .05);
  font-size: 30px;
  margin-bottom: 20px;
  padding: 10px;

  @for $i from 1 through 5 {

    &:#{nth-child($i)} {
      min-height: random(200) + 50px;
    }
  }

  &.red {
    background-color: rgba(red, .25);
  }
}
