@import '../../../../../styles/base';

.change-password-modal {
  .form {
    margin: auto;
    text-align: center;
    max-width: 332px;

    .input {
      margin-bottom: 30px;
    }
  }
}
