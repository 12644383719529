@import '../../../styles/base';
@import './DropdownVariables';

.dropdown-content {
  display: none;
  position: absolute;
  z-index: 100;

  &__body {
    background-color: color(pbg);
    border-radius: 4px;
    box-shadow: 0 0 10px color(gray-l);
    min-height: map-get($dropdown, list-item-size);
    min-width: 150px;
  }

  // positions
  @each $first in bottom, left, right, top {
    @each $second in direction($first, top center bottom, left center right) {
      $second-center: direction($first, top, left);
      $x: 0;
      $y: 0;

      &--#{$first}-#{$second} {
        $first-reverse: 'top';
        $rotate: 0deg;

        @if $first == 'bottom' {
          $y: 100%;
        } @else if $first == 'top' {
          $first-reverse: 'bottom';
          $y: -100%;
        } @else if $first == 'left' {
          $first-reverse: 'right';
          $x: -100%;
        } @else if $first == 'right' {
          $first-reverse: 'left';
          $x: 100%;
        }

        @if $second == 'center' {
          @if $second-center == 'left' {
            $x: -50%;
          } @else {
            $y: -50%;
          }
          #{$second-center}: 50%;
        } @else {
          #{$second}: 0;
        }

        #{$first}: 0;
        margin: -map-get($dropdown, shadow-size);
        margin-#{$second-center}: 0;
        padding: map-get($dropdown, shadow-size);
        padding-#{$first-reverse}: map-get($dropdown, side-padding);
        transform: translate($x, $y);

        > .dropdown-triangle {
          #{direction($first, height, width)}: 100%;
          #{direction($first, width, height)}: 2 *
            map-get($dropdown, triangle-size);
          #{$first-reverse}: map-get($dropdown, triangle-margin);
          #{$second-center}: 0;

          .dropdown-triangle__square {
            #{$first-reverse}: 0;

            @if $second == 'center' {
              #{$second-center}: 50%;
              transform: rotate($rotate)
                #{'translate' +
                direction($first, Y, X) +
                '(-50%)'};
            } @else {
              #{$second}: map-get($dropdown, triangle-offset) +
                map-get($dropdown, shadow-size);
              transform: rotate($rotate);
            }

            &::after {
              #{$first-reverse}: map-get($dropdown, triangle-direction-size);
              #{$first}: auto;
              #{$second-center}: map-get($dropdown, triangle-centering-size);
            }
          }
        }
      }
    }
  }
}
