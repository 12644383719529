@import '../../dm-public/ui-kit/variables';

.footer {
  background: $bg;
  margin: 0 20px;
  border-top: 1px solid $line;

  @include breakpoint(desktop) {
    margin: 0 40px;
  }

  @include breakpoint(mobile) {
    margin: 0 10px;
  }

  .footer-container {
    @extend %container;
  }

  .row {
    padding: 40px 0 20px 0;
    border-bottom: 1px solid $line;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @include breakpoint(tablet_l) {
      margin: 0 40px;
    }
    @include breakpoint(tablet_p) {
      justify-content: flex-start;
      margin: 0 40px;
    }
  }

  .navigation {
    flex: auto;
    @include breakpoint(tablet_p) {
      width: 100%;
      margin-bottom: 10px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    > nav {
      display: inline-flex;
      flex-direction: column;
      @include breakpoint(tablet_l, infinity) {
        width: 33%;
      }
      @include breakpoint(mobile) {
        width: 50%;
      }
    }
    h5 {
      text-transform: uppercase;
      margin-bottom: 20px;
    }
  }

  .nav_list {
    a {
      color: $blue;
      font-size: 15px;
      font-weight: 700;
      line-height: 18px;

      &:hover {
        color: $blue-active;
        text-decoration: underline;
      }
    }

    li {
      margin-bottom: 20px;
      line-height: 0;
    }
  }

  .social_link {
    display: inline-flex;
    align-items: center;

    i {
      line-height: 0;
      margin-right: 10px;
    }
  }

  .subscribe_form {
    max-width: 390px;

    h5 {
      margin-bottom: 10px;
    }

    p {
      margin-bottom: 20px;
    }
  }

  .information {
    padding: 40px 0 20px 0;

    > div {
      margin-bottom: 20px;
    }
    @include breakpoint(tablet_l) {
      margin: 0 40px;
    }
    @include breakpoint(tablet_p) {
      margin: 0 40px;
    }
  }

  .bottom {
    border-top: 1px solid $line;
    padding: 15px 0 15px 0;
    line-height: 0;

    .footer-container {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      @include breakpoint(tablet_p, zero) {
        max-width: 500px;
        justify-content: center;
      }
      @include breakpoint(tablet_l) {
        padding: 0 40px;
      }
    }

    .copyright {
      margin: 0 20px;
      @include breakpoint(tablet_l, infinity) {
        flex: 1;
      }
      @include breakpoint(tablet_p) {
        margin-bottom: 20px;
        line-height: 32px;
      }
      @include breakpoint(mobile) {
        width: 100%;
        text-align: center;
        margin: 0 0 10px;
      }
    }

    .logo {
      line-height: 0;
      display: inline-block;
      margin: 0 20px 0 0;
      @include breakpoint(tablet_p) {
        margin: 0 20px 20px;
      }
      @include breakpoint(mobile) {
        width: 100%;
        text-align: center;
        margin: 0 0 10px;
      }
    }

    .security {
      margin: 0 0 0 20px;
      @include breakpoint(tablet_p, zero) {
        margin: 0 20px;
      }
      @include breakpoint(mobile) {
        width: 100%;
        text-align: center;
      }
    }
  }
}
