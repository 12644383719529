@import '../../../../styles/base';

.avatar {
  border: 1px solid color(gray-l);
  border-radius: 4px;
  box-sizing: border-box;
  height: 201px;
  // overflow: hidden;
  position: relative;
  width: 201px;

  @include breakpoint(mobile) {
    height: 122px;
    width: 122px;
  }

  .avatar-image {
    width: 100%;
    height: 100%;
  }

  &__overlay {
    align-items: center;
    background: rgba(color(blue-dm), 0.8);
    bottom: 0;
    cursor: pointer;
    display: flex;
    justify-content: center;
    height: 0;
    left: 0;
    position: absolute;
    right: 0;
    opacity: 0;
    transition: opacity, height 400ms;

    @include breakpoint(mobile) {
      height: 35px;

      .icon--photo {
        transform: scale(0.8);
      }
    }

    &__caption {
      color: color(white);
      margin-left: 10px;

      @include breakpoint(mobile) {
        font-size: 10px;
        line-height: 10px;
        margin-left: 6px;
        text-transform: uppercase;
      }
    }
  }

  &__extensions {
    font-size: 12px;
    position: static;
    text-align: center;
    width: 100%;
  }

  &:hover,
  &--active {
    .avatar__overlay {
      height: 100%;
      opacity: 1;
    }
  }

  &--error & {
    &__overlay {
      background: rgba(color(red-error), 0.8);
    }
  }

  & + .dropzone__helper {
    position: static;
  }
}
