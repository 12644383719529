@import '../../../../styles/base';

.ready-invest-amount-modal {
  @include breakpoint(mobile) {
    .modal__header__title {
      font-size: 17px;
    }
  }
  .checkable {
    margin-bottom: 10px;

    label {
      padding-left: 30px;
    }
  }

  &__container {
    margin: 20px auto 0;
    width: 292px;

    .form-group {
      margin-bottom: 0;
    }
  }
}
