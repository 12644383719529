@import './base';

// Flexbox
.fb {
  display: flex;

  &--row {
    flex-direction: row;
  }

  &--col {
    flex-direction: column;
  }

  &--items {

    &-left {
      align-items: flex-start;
    }

    &-center {
      align-items: center;
    }
  }

  &--content {

    &-center {
      justify-content: center;
    }

    &-between {
      justify-content: space-between;
    }

    &-around {
      justify-content: space-around;
    }
  }
}

// Text colors
.tc {

  @each $colorName, $colorValue in $colors {
    &--#{$colorName} {
      color: $colorValue;
      line-height: 1;
    }
  }
}

// Other
.hidden {
  visibility: hidden;
}
