@import '../../../styles/base';

.message--note {
  border-bottom: 1px solid color(sbg);

  .checkable {
    left: 30px;
    position: absolute;

    @include breakpoint(mobile) {
      left: 20px;
    }
  }
}
