@import '../../../../styles/base';

.kyc-status-button {
  &__label {
    align-items: center;
    margin-top: 5px;
    color: color(green-d);
    display: flex;
    font-size: 14px;
    font-weight: bold;
  }

  .icon {
    margin-right: 10px;

    @include breakpoint(mobile, zero) {
      margin-right: 10px;
    }
  }
}
