@import '../../../styles/base';

.inbox {
  position: relative;
  border-left: 1px solid color(gray-vl);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-top: 70px;
  padding-bottom: 55px;
  min-width: 280px;
  width: 20%;
  background: color(white);

  @include breakpoint(desktop, zero) {
    width: 25%;
  }

  @include breakpoint(tablet, zero) {
    background: color(white);
    display: none;
    height: 100vh;
    left: 0;
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 70px;
    padding-bottom: 45px;
    position: fixed;
    right: 0;
    width: 100%;
    z-index: 99;
  }

  &--opened {
    @include breakpoint(tablet, zero) {
      display: flex;
    }
  }

  &__header {
    align-items: center;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
    display: flex;
    padding: 10px;

    @include breakpoint(desktop, infinity) {
      display: none;
    }
  }

  &__avatar {
    margin-right: 20px;
  }

  &__title {
    color: color(headings);
    font-size: 18px;
  }

  &__back-btn {
    margin-right: 60px;
  }

  &__message-items {
    display: flex;
    flex-direction: column-reverse;
  }

  &__new-message {
    border-top: 1px solid color(gray-vl);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    position: relative;
    text-align: right;

    &__form__controls {
      display: flex;

      @include breakpoint(tablet, zero) {
        justify-content: center;
      }
    }

    .form-group {
      @include breakpoint(tablet) {
        margin-bottom: 22px;
      }

      @include breakpoint(mobile) {
        flex: 1;
        margin-bottom: 10px;
      }

      .text-area {
        @include breakpoint(mobile) {
          min-height: 28px;
        }

        textarea {
          @include breakpoint(mobile) {
            padding: 12px;
            padding-right: 105px;
            max-height: 100px;
          }
        }
      }
    }

    &__label {
      align-items: center;
      display: flex;
      margin-right: 10px;
      text-align: left;
      width: calc(100% - 94px);

      @include breakpoint(tablet, zero) {
        display: none;
      }
    }

    .text-area__helper {
      font-size: 13px;
      text-align: left;
    }
  }

  &--scrollable {
    @extend %dm-scroll;
    display: flex;
    flex: 1;
    flex-direction: column-reverse;
    overflow-y: auto;
    height: calc(100vh - #{$activity-feeds-height});

    & + .btn--white {
      @extend %horizontal-center;
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.4);
      color: color(blue-g);
      font-size: 14px;
      top: 18px;

      &:active {
        transform: translateX(-50%);
      }

      &:hover {
        opacity: 1;

        button {
          opacity: 0.8;
        }
      }
    }
  }

  &__send-btn {
    @include breakpoint(tablet) {
      min-height: 36px;
      max-height: 36px;
    }

    @include breakpoint(mobile) {
      font-size: 13px;
      min-height: 28px;
      max-height: 28px;
      position: absolute;
      right: 40px;
      top: 30px;
    }
  }
}
