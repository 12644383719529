@import '../../../../styles/base';

.delete-panel {
  .panel__content {
    align-items: center;
    display: flex;
    flex-direction: row;
    padding: 15px 39px 14px 30px;
  }

  .btn {
    margin: 0 20px 0 0;
  }

  &__text {
    color: color(headings);
    flex: 1;
  }

  &__description {
    color: color(texts);
  }

  &--reverse {
    .panel__content {
      flex-direction: row-reverse;
    }

    .btn {
      margin: 0 0 0 20px;
    }
  }
}
