@import '../../../styles/base';

.header {
  display: flex;
  left: 0;
  right: 0;
  z-index: $header-z-index;
  background: color(pbg);

  @include breakpoint(desktop, infinity) {
    padding: 0 40px;
  }

  @include breakpoint(tablet) {
    padding: 0 20px;
  }

  @include breakpoint(mobile) {
    padding: 0 10px;
  }

  ~ aside,
  ~ main {
    margin-top: $header-fixed-height;
  }

  &__menu {
    @include breakpoint(desktop, infinity) {
      display: none;
    }

    button {
      display: flex;
      padding: 0 20px;
    }
  }

  &__logo {
    display: inline-flex;
  }

  &__info {
    display: inline-flex;
    flex: 1;
    overflow: hidden;
  }

  &--with-aside {
    padding-left: 0;
  }

  // positions
  &--fixed {
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);
    color: color(headings);
    height: $header-fixed-height;
    position: fixed;

    .dropdown:hover .header-nav-link,
    .header-nav-link:hover,
    .header-nav-link--active {
      color: color(blue-m);
    }
  }

  &--absolute {
    background: transparent;
    color: color(headings);
    position: absolute;
    height: $header-fixed-height;

    .dropdown:hover .header-nav-link,
    .header-nav-link:hover,
    .header-nav-link--active {
      color: color(blue-m);
    }
  }

  &--public {
    background: color(sbg);
  }
}
