@import '../../../../styles/base';

.kyc-declined-modal {
  p {
    margin-top: 0;
    text-align: justify;
  }

  .modal__controls {
    @include breakpoint(mobile) {
      flex-direction: column-reverse;

      .btn {
        margin: 0 0 20px;
      }
    }
  }
}
