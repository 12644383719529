@import '../variables';

.notification {
  width: 100%;
  padding: 20px;
  margin-bottom: 20px;
  background: $white;
  border-radius: 4px;
  border: 1px solid $line;
  position: relative;

  .close {
    top: 22px;
    right: 20px;
    position: absolute;
    padding: 0;
    margin: 0;
    border: none;
    background: none;
    outline: 0;
    line-height: 0;
    cursor: pointer;

    &:hover svg {
      fill: $caption;
    }
  }

  h5 {
    padding-right: 40px;
    margin-bottom: 10px;
  }

  p {
    font-size: 14px;
    line-height: 22px;
  }

  .row {
    display: flex;
    align-items: flex-start;
  }

  .image {
    flex-shrink: 0;
    margin-right: 20px;
  }

  &.success {
    background: $green-highlight;
    border: 1px solid $green-line;
    .image svg {
      fill: $green;
    }
  }

  &.danger {
    background: $red-highlight;
    border: 1px solid $red-line;
    .image svg {
      fill: $red;
    }
  }
}
