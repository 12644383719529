@import '../../../../styles/base';

.activity-feeds {
  &__sidebar {
    &-row {
      display: flex;
      margin-bottom: 5px;

      @include breakpoint(desktop-large, infinity) {
        width: 150px;
      }

      &:last-child {
        margin-bottom: 10px;
      }

      &#{&}--custom {
        display: flex;
        flex-wrap: wrap;
        margin-top: 20px;

        &:first-child {
          margin-top: 0;
        }

        .activity-feeds__sidebar-year {
          @include breakpoint(desktop-large, infinity) {
            flex: 0;
            min-width: 32px;
          }
        }

        .activity-feeds__sidebar-button {
          margin: 0 18px;

          @include breakpoint(tablet, zero) {
            margin-left: 0;
          }
        }
      }
    }

    &-button {
      background: color(blue-l);
      cursor: pointer;
      font-size: 0;
      height: 22px;
      margin-right: 18px;
      transition: background 0.2s;
      width: 6px;

      @include breakpoint(desktop-large, infinity) {
        margin-left: 50px;
      }

      &#{&}--active,
      &:hover {
        background: color(blue-d);
      }
    }

    &-year {
      flex: 100%;
      margin-bottom: 7px;

      @include breakpoint(desktop-large, infinity) {
        flex: 0;
        min-width: 32px;
      }
    }
  }

  &--small & {
    &__sidebar {
      .activity-feeds__sidebar-button {
        margin: 0 13px 0 0;
      }
    }
  }
}
