@import '../../../styles/base';
@import '../FormControl/FormControlVariables';

.form-control-static {
  align-items: center;
  color: $base-color;
  display: flex;
  height: $base-height;

  &--md {
    height: $md-height;
  }
}
