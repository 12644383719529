@import '../../../../styles/base';

.sub-doc-modal {
  .modal__body {
    min-height: 274px;
    box-sizing: border-box;
  }

  &__text {
    text-align: center;
    max-width: 540px;
    margin: 0 auto 25px;

    a {
      font-weight: 700;
      text-decoration: underline;
      color: color(blue-m);
    }
  }

  &__dropzone {
    text-align: center;
  }

  &__file-types {
    text-align: center;
    margin: 15px 0 6px;
  }

  &__file-name {
    color: color(headings);
  }

  &__file {
    display: inline-flex;
    position: relative;
    text-align: left;
    padding-left: 65px;
    flex-direction: column;
    align-items: flex-start;
    margin: 37px 0 12px;
    min-height: 57px;
    width: 100%;
    max-width: 455px;

    @include breakpoint(tablet, zero) {
      padding-left: 45px;
    }
  }

  .icon-lg {
    position: absolute;
    left: 0;
    top: 0;
  }

  .modal__controls {
    flex-direction: column;

    &.submit {
      margin-bottom: 24px;
    }
  }

  &__change-btn {
    border: none;
    min-height: 36px;
    margin: 0;

    @include breakpoint(tablet, zero) {
      font-size: 12px;
    }

    button {
      padding: 0;
    }
  }

  &__progress {
    width: 100%;
    max-width: 520px;
    display: inline-block;
    text-align: left;
    height: 4px;
    padding: 55px 0 45px;

    > div {
      width: 0%;
      height: 4px;
      background: #617f9c;
      transition: width 0.3s linear;
    }
  }

  .modal__controls {
    margin-top: 30px;
  }
}
