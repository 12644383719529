@font-face {
  font-family: acumin-pro;
  src:  url('dm-public/ui-kit/fonts/acumin-pro/acumin-pro-700.woff2') format('woff2'),
        url('dm-public/ui-kit/fonts/acumin-pro/acumin-pro-700.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: acumin-pro;
  src:  url('dm-public/ui-kit/fonts/acumin-pro/acumin-pro-italic-700.woff2') format('woff2'),
        url('dm-public/ui-kit/fonts/acumin-pro/acumin-pro-italic-700.woff') format('woff');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: acumin-pro;
  src:  url('dm-public/ui-kit/fonts/acumin-pro/acumin-pro-400.woff2') format('woff2'),
        url('dm-public/ui-kit/fonts/acumin-pro/acumin-pro-400.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: acumin-pro;
  src:  url('dm-public/ui-kit/fonts/acumin-pro/acumin-pro-italic-400.woff2') format('woff2'),
        url('dm-public/ui-kit/fonts/acumin-pro/acumin-pro-italic-400.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: acumin-pro;
  src:  url('./dm-public/ui-kit/fonts/acumin-pro/acumin-pro-300.woff2') format('woff2'),
        url('./dm-public/ui-kit/fonts/acumin-pro/acumin-pro-300.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: acumin-pro;
  src:  url('./dm-public/ui-kit/fonts/acumin-pro/acumin-pro-italic-300.woff2') format('woff2'),
        url('./dm-public/ui-kit/fonts/acumin-pro/acumin-pro-italic-300.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: museo-sans-rounded;
  src:  url('dm-public/ui-kit/fonts/museo-sans-rounded/museo-sans-rounded-500.woff2') format('woff2'),
        url('dm-public/ui-kit/fonts/museo-sans-rounded/museo-sans-rounded-500.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: museo-sans-rounded;
  src:  url('dm-public/ui-kit/fonts/museo-sans-rounded/museo-sans-rounded-700.woff2') format('woff2'),
        url('dm-public/ui-kit/fonts/museo-sans-rounded/museo-sans-rounded-700.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}
