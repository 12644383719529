@import '../../../styles/base';
@import './DropdownVariables';

.dropdown-list-item {
  @extend %with-hover;
  border-bottom: 1px solid color(sbg);
  box-sizing: border-box;
  color: color(headings);
  cursor: pointer;
  display: flex;
  height: map-get($dropdown, list-item-size);
  user-select: none;

  &:last-child {
    border-bottom: none;
  }

  &__content {
    display: flex;
    flex: 1;
    padding: 0 20px;
  }

  &__body {
    align-items: center;
    display: flex;
    flex: 1;
    white-space: nowrap;

    .link {
      align-self: stretch;
      flex: 1;
    }
  }

  &__text {
    flex: 1;
    line-height: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__icon-left {
    margin-right: map-get($dropdown, icon-margin);
  }

  &__icon-right {
    order: 1;
    margin-left: map-get($dropdown, icon-margin);
  }

  &__arrow-right {
    order: 2;
    margin-left: map-get($dropdown, icon-margin);
  }
}
