@import '../../../../../styles/base';

.fund-wizard-modal {
  &__title {
    text-transform: uppercase;
  }

  @include breakpoint(mobile) {
    .form {
      display: flex;
      flex: 1;
      flex-direction: column;
    }
  }

  &__terms {
    padding: 0 65px;

    @include breakpoint(mobile) {
      height: 100%;
      padding: 0;
    }

    .terms__text {
      height: 230px;
      overflow: auto;

      @extend %dm-scroll;
      color: color(texts);
      font-size: 14px;
      text-align: justify;

      @include breakpoint(mobile) {
        height: 260px;
        padding: 0;
      }

      &__title {
        font-size: 17px;
        font-weight: 700;
        text-transform: uppercase;
      }
    }

    .form-group {
      justify-content: space-between;
      margin: 57px 0 0;
      padding: 0 20px;

      @include breakpoint(mobile) {
        flex-direction: column;
        margin-top: 40px;
        padding: 0;

        button {
          font-size: 16px;
        }
      }
    }

    &__signature {
      display: flex;

      @include breakpoint(mobile) {
        justify-content: center;
      }

      .Select {
        flex: 0 0 270px;
        margin-right: 18px;

        @include breakpoint(mobile) {
          margin: 0;
        }

        &.is-focused.is-searchable.is-open:not(.has-value) {
          .Select-input {
            padding: 0;

            &:before {
              display: none;
            }
          }
        }
      }
    }

    &__change-signature {
      display: flex;
      flex-direction: column;

      &__name {
        border-bottom: 1px solid color(headings);
        padding: 0 26px 4px;
        margin-bottom: 6px;
        text-align: center;
      }

      .btn {
        display: flex;
        align-self: center;
        font-size: 12px;
      }
    }

    &__date {
      font-size: 18px;
      font-weight: 700;

      @include breakpoint(mobile) {
        font-size: 16px;
        margin-top: 35px;
      }
    }
  }
}
