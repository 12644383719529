@import '../../../styles/base';

.message {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 1px;

  &:hover {
    background: color(blue-lg2);
    border-radius: 4px;
  }

  &:hover & {
    &__controls {
      display: flex;
    }

    &__author-info {
      &__name {
        color: color(blue-m);
      }
    }
  }

  &--selected {
    background: color(blue-l);
    border-radius: 4px;
  }

  &--new & {
    &__content {
      &::after {
        background: color(blue-g);
        content: '';
        display: block;
        height: 100%;
        left: -10px;
        position: absolute;
        top: 0;
        width: 1px;
      }
    }
  }

  &__container {
    padding: 10px 31px 10px 20px;
    position: relative;
  }

  &__content {
    position: relative;
  }

  &__controls {
    display: none;
    position: absolute;
    right: -19px;
    top: -12px;

    @include breakpoint(tablet, zero) {
      display: flex;
    }

    @include breakpoint(mobile, zero) {
      flex-direction: column-reverse;
    }

    .btn {
      &:last-child {
        @include breakpoint(mobile) {
          margin-bottom: 16px;
        }
      }
    }
  }

  &__header {
    color: color(headings);
    display: flex;
    flex: 1;
    font-size: 16px;
  }

  &__author-info {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-left: 10px;

    &__name {
      display: flex;
      line-height: 18px;
    }

    &__type {
      color: color(blue-m);
      font-size: 12px;
      font-weight: 700;
      margin-left: 10px;
      position: relative;
      text-transform: lowercase;
    }
  }

  &__meta {
    display: flex;
    justify-content: space-between;
  }

  &__date {
    color: color(gray-d);
    font-size: 12px;
    line-height: 16px;
  }

  &__new-message {
    color: color(gray-d);
    font-size: 12px;
    line-height: 16px;

    @include breakpoint(desktop-large, zero) {
      display: none;
    }
  }

  &__text {
    margin: 20px 0 0;
    word-break: break-all;

    @include breakpoint(mobile) {
      padding-right: 35px;
    }
  }
}
