@import '../../../styles/base';

.overlay-spinner {
  background-color: rgba(color(black), 0.2);
  bottom: 0;
  display: flex;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 99;

  .dots-spinner {
    @extend %center-center;
  }
}
