@import '../variables';

.caption {
  color: $caption;
  font-size: 12px;
  line-height: 19px;

  &.error {
    color: $red;
  }

  a {
    color: $paragraph;
    font-size: 12px;
    font-weight: normal;
    text-decoration: underline;

    &:hover {
      color: $blue;
    }
  }
}
