@import '../../../../../../styles/base';

.fund-show-previous-funds {
  padding-bottom: 30px;

  &__title {
    font-size: 24px;
    margin-bottom: 22px;
    text-align: center;
    text-transform: uppercase;

    @include breakpoint(tablet, zero) {
      color: color(headings);
      font-size: 18px;
      margin-bottom: 35px;
    }
  }

  &__content {
    display: flex;
    flex-wrap: wrap;

    @include breakpoint(mobile) {
      flex-direction: column;
    }
  }

  &__item {
    background-color: color(sbg);
    border-radius: 4px;
    box-sizing: border-box;
    margin-bottom: 30px;
    padding: 0 10px;
    width: calc(50% - 15px);

    @include breakpoint(tablet) {
      margin-bottom: 20px;
      width: calc(50% - 10px);
    }

    @include breakpoint(mobile) {
      margin-right: 0;
      width: 100%;
    }

    &:nth-child(odd) {
      margin-right: 30px;

      @include breakpoint(tablet) {
        margin-right: 20px;
      }

      @include breakpoint(mobile) {
        margin-right: 0;
      }
    }

    &__row {
      align-items: center;
      border-bottom: 1px solid color(pbg);
      color: color(texts);
      display: flex;
      font-size: 14px;
      height: 48px;

      @include breakpoint(tablet) {
        font-size: 12px;
        font-weight: 700;
        padding: 0 10px;
      }

      &:last-child {
        border-bottom: 0;
      }

      &__label {
        display: flex;
        flex: 0 0 190px;
        font-weight: 400;

        @include breakpoint(mobile) {
          flex: 1 0 150px;
        }
      }

      &__value {
        @include breakpoint(mobile) {
          flex: 4;
        }
      }
    }
  }
}
